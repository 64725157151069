.investment {
  padding: 13.125rem 0 10rem;
  background-image: url("../../assets/images/bg/media.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    display: block;
    font-family: "Pretendard";
    font-size: 3rem;
    font-weight: 600;
    color: #5b54fe;
    margin-bottom: 2.5rem;
  }

  &-group {
    max-width: 97.875rem;
    margin: 0 auto;
    padding: 5rem;
    border-radius: 3rem;
    background-image: url("../../assets/images/bg/media_blur.png");
  }

  &-box {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 5rem;
    border-radius: 3rem;
    background-color: #f8f9ff;
    border: solid 2px rgba(255, 255, 255, 0.48);
  }

  &-list {
    .table-header-box {
      display: flex;
      column-gap: 0.5rem;
      border-bottom: 1px solid rgba(141, 150, 171, 0.2);
      width: 100%;
      min-width: 67.4375rem;
    }

    div {
      padding: 0.375rem 0;
      color: #8d96ab;
      font-family: "Pretendard";
      font-weight: 600;
    }

    .date {
      flex-shrink: 0;
      width: 13.25rem;
    }
    .content {
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 39.9375rem;
    }
    .writer {
      flex-shrink: 0;
      width: 13.25rem;
      text-align: center;
    }

    li {
      width: 100%;
      min-width: 67.4375rem;
    }
  }

  &-content-list {
    display: flex;
    column-gap: 0.5rem;
    border-bottom: 1px solid rgba(141, 150, 171, 0.2);
    cursor: pointer;

    div {
      padding: 0.375rem 0;
      color: #1d212e;
      font-family: "Pretendard";
      font-weight: 400;
      line-height: 1.5;
    }

    .date {
      flex-shrink: 0;
      width: 13.25rem;
    }
    .content {
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 39.9375rem;
    }
    .writer {
      flex-shrink: 0;
      width: 13.25rem;
      text-align: center;
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  max-width: max-content;
  width: 100%;
  margin: 3rem auto 0;
  padding: 2.0625rem 3.1875rem;
  column-gap: 2.0625rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: solid 2px rgba(255, 255, 255, 0.55);
  border-radius: 999px;
  background-image: linear-gradient(97deg, #adadad 1%, #9c9d9f 98%);

  &-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.875rem;

    button {
      font-size: 1.25rem;
      color: #fff;

      &:hover {
        font-weight: 700;
      }
    }
  }
}

.bg-wrapper {
  padding-bottom: 10rem;
  background-color: #1d212e;
}

.investment-view {
  padding: 16.25rem 11.0625rem;
  background-image: url("../../assets/images/bg/media.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &-group {
    padding: 5rem;
    border-radius: 3rem;
    background-image: url("../../assets/images/bg/media_blur.png");
    border: solid 2px rgba(255, 255, 255, 0.48);
  }

  &-box {
    padding: 5rem 5rem 2.5rem 5rem;
    background-color: #f8f9ff;
    border-radius: 1.5rem;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
  }

  .title {
    margin-bottom: 1.5rem;
    font-family: "Pretendard";
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #5b54fe;
  }
  .content-title {
    display: block;
    margin-bottom: 2.5rem;
    font-family: "Pretendard";
    font-size: 3rem;
    line-height: 1.21;
    color: #1d212e;
  }
  .content {
    font-family: "Pretendard";
    font-size: 1rem;
    line-height: 1.5;
    color: #1d212e;

    &-box {
      padding: 2.5rem 0;
      border-top: 1px solid rgba(141, 150, 171, 0.2);
      border-bottom: 1px solid rgba(141, 150, 171, 0.2);
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8.75rem;
      height: 2.75rem;
      margin: 2.5rem auto 0;
      border: 1px solid #8d96ab;
      border-radius: 999px;
      font-family: "Pretendard";
      font-weight: 600;
    }
  }
}

.bg-area {
  padding-bottom: 10rem;
  background-color: #1d212e;
}

// common
.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .investment {
    padding: 210px 0 160px;

    .title {
      font-size: 48px;
      margin-bottom: 40px;
    }

    &-group {
      max-width: 1566px;
      padding: 80px;
    }

    &-box {
      padding: 80px;
    }

    &-list {
      .table-header-box {
        width: 100%;
        min-width: 1079px;
      }

      div {
        padding: 0.375rem 0;
      }

      .date {
        width: 212px;
      }
      .content {
        flex-basis: 639px;
      }
      .writer {
        width: 212px;
      }

      li {
        min-width: 1079px;
      }
    }

    &-content-list {
      div {
        padding: 6px 0;
      }

      .date {
        width: 212px;
      }
      .content {
        flex-basis: 639px;
      }
      .writer {
        width: 212px;
      }
    }
  }

  .bg-wrapper {
    padding-bottom: 160px;
  }

  .investment-view {
    padding: 260px 177px;

    &-group {
      padding: 80px;
    }

    &-box {
      padding: 80px 80px 40px 80px;
    }

    .title {
      margin-bottom: 24px;
      font-size: 40px;
    }
    .content-title {
      margin-bottom: 2.5rem;
      font-size: 48px;
    }
    .content {
      font-size: 16px;

      &-box {
        padding: 40px 0;
      }

      a {
        width: 140px;
        height: 44px;
        margin: 40px auto 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .investment {
    &-group {
      padding: 2.5rem;
    }
    &-box {
      padding: 2.5rem;
    }

    .title {
      font-size: 2rem;
    }
  }
  .scroll-x {
    overflow-x: auto;
    padding-bottom: 0.625rem;
    padding-right: 1.25rem;

    &::-webkit-scrollbar {
      border-radius: 999px;
      height: 2px;
      background-color: #707070;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5b54fe;
      border-radius: 999px;
    }
    &::-webkit-scrollbar-track {
    }
  }

  .pagination {
    margin-top: 2rem;
    padding: 1.5rem 3.625rem;
  }
  .bg-wrapper {
    padding-bottom: 4rem;
  }
  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}

// @media screen and (max-width: 768px) {
//   .pagination {
//     display: none;
//   }
// }
