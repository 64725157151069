.content-box {
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(3.125rem);
  backdrop-filter: blur(3.125rem);
  border: solid 1px var(--color-border-gray);
}

.box-title {
  color: #707070;
  font-size: 1rem;
}
.list-title {
  color: #707070;
  font-size: 0.875rem;
}

.items-center {
  display: flex;
  justify-content: space-between;
}

.list-wrapper {
  .list-header {
    padding: 0 1rem;
    background-color: var(--color-bg-light-gray);
    border-radius: 0.25rem;
    color: var(--color-gray);

    .list-box {
      // ul
      display: flex;
      justify-content: space-between;
      column-gap: 15px;

      .title-list-item {
        // li

        .header-text {
          width: 100%;
          padding: 1rem 0;
          text-align: center;
        }
      }
    }
  }
}

.list-container {
  .list-box {
    // ul
    margin: 0.5rem 0 1rem;
    max-height: 36.875rem;
    overflow-y: auto;

    // 스크롤바 전체
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    // 스크롤 막대
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray);
      border-radius: 999px;
    }
    // 스크롤 막대 외부
    &::-webkit-scrollbar-track {
      background-color: #e2e8f0;
      border-radius: 999px;
    }

    .content-list-item {
      // li
      display: flex;
      justify-content: space-between;
      column-gap: 15px;
      padding: 0 1rem;
      border-bottom: 1px solid var(--color-border-gray);
      cursor: pointer;

      &.active {
        background-color: #d3eee5;
      }

      .list-item {
        padding: 1rem 0;
        text-align: center;

        > div {
          width: 100%;
        }
      }
    }
  }
}
