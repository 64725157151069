$delay-time: (0.05s, 0.1s, 0.15s, 0.2s, 0.25s, 0.3s, 0.35s, 0.4s, 0.45s);

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  row-gap: 0.5rem;

  .scroll-icon {
    z-index: 1;
    position: absolute;
    left: 50%;
    bottom: 3.75rem;
    width: 1.5rem;
    height: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.21);
    border-radius: 999px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0.25rem;
      transform: translateX(-50%);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: rgb(255, 255, 255, 0.53);
    }
  }

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inner {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    padding-bottom: 17.875rem;
  }

  &-text {
    flex-shrink: 0;
    white-space: nowrap;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.25;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          display: inline-block;
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }

    br {
      display: none;
    }
  }

  &-en-text {
    font-size: 3.5rem;
    font-weight: 700;
  }
  &-ko-text {
    font-size: 3.5rem;
    font-family: "Pretendard";
    font-weight: 700;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12.3125rem;
    height: 3.375rem;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    background-color: #5b54fe;
    border-radius: 999px;
    box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
    font-family: "Pretendard";
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.solution {
  width: 100%;
  padding: 5.8125rem 0 0;
  height: 117.0625rem;
  background: url("../../assets/images/bg/main_solution.jpg") no-repeat top 5%
    center / cover;

  .title {
    margin-bottom: 4.9375rem;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.32;
    text-align: center;
  }

  &-box {
    position: relative;
    max-width: 90rem;
    width: 100%;
    height: 46.375rem;
    margin: 0 auto;
    padding: 4.875rem 7.75rem 5.6875rem;
    box-shadow: 0.75rem 1.5rem 3rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 57.8125rem 0 0;
    background-color: rgb(230, 233, 233, 0.85);

    + .solution-box {
      margin-top: 2.5rem;
      border-radius: 0 0 57.8125rem 0;
    }

    .bg-pola {
      position: absolute;
      top: 7.625rem;
      right: 5.625rem;
      width: 68.75rem;
      height: 33rem;
    }
    .bg-tivv {
      position: absolute;
      top: 7.625rem;
      right: 5.625rem;
      width: 68.75rem;
      height: 33rem;
    }
    .sub-text {
      z-index: 1;
      position: relative;
      display: block;
      width: max-content;
      margin-bottom: 1.75rem;
      color: #8d96ab;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.3;
    }

    > div {
      z-index: 1;
      position: relative;

      > p:nth-child(3) {
        height: 20px;
      }
      > p:nth-child(n + 4) {
        span {
          font-weight: 400;
          line-height: 1.5;
          font-family: "Pretendard";
        }
      }

      span {
        font-weight: 500;
        line-height: 1.25;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10.4375rem;
      height: 3.25rem;
      margin-top: 1.875rem;
      font-size: 1.25rem;
      line-height: 1.2;
      color: #fff;
      background-color: #5b54fe;
      border-radius: 999px;
    }
  }

  &-en-box {
    .bg-pola {
      height: 24rem;
      right: -2.375rem;
      top: 15.625rem;
      width: 54.75rem;
    }
    .bg-tivv {
      height: 24rem;
      right: -2.375rem;
      top: 15.625rem;
      width: 54.75rem;
    }
  }
}

.partners {
  padding: 6.5rem 0 6.25rem;
  background-image: linear-gradient(to bottom, #fff, #e0dfe4);

  .title {
    margin-bottom: 2.4375rem;
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1.3167;
    text-align: center;
  }

  .sub-title {
    display: block;
    margin-bottom: 6rem;
    font-size: 1.5rem;
    text-align: center;
    font-family: "Pretendard";
  }

  .marquee-wrapper {
    row-gap: 6.25rem;

    ul {
      margin-left: 1.5rem;
      column-gap: 1.5rem;
    }

    li {
      width: 19.875rem;
      height: 8rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.bg-area {
  padding-bottom: 10rem;
  background-color: #1d212e;
}

.awards {
  padding: 5.8125rem 0 12.0625rem;
  background-image: url("../../assets/images/bg/main_awards_1.png"),
    url("../../assets/images/bg/main_awards_2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: left 9.625rem top, right 10.375rem top 6.375rem;

  .title {
    margin-bottom: 6rem;
    font-size: 3.75rem;
    color: #fff;
    text-align: center;
  }

  .swiper-slide {
    .trophy-image {
      z-index: 1;
      position: absolute;
      top: 0.25rem;
      left: 2rem;
      width: 11.375rem;
      height: 12.75rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 12.75rem;
    }

    &:nth-child(1n) {
      .trophy-image {
        background-image: url("../../assets/images/items/awards/trophy_1.png");
      }
    }
    &:nth-child(2n) {
      .trophy-image {
        background-image: url("../../assets/images/items/awards/trophy_2.png");
      }
    }
    &:nth-child(3n) {
      .trophy-image {
        background-image: url("../../assets/images/items/awards/trophy_3.png");
      }
    }
    &:nth-child(4n) {
      .trophy-image {
        background-image: url("../../assets/images/items/awards/trophy_4.png");
      }
    }
  }

  .list-box {
    height: 18.6875rem;
    margin-top: 8.875rem;
    padding: 5.375rem 2.25rem 4.25rem 2.875rem;
    border-radius: 1.5rem;
    letter-spacing: -0.02em;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: solid 1px rgba(255, 255, 255, 0.24);
    background: rgba(53, 57, 69, 0.7);

    .list-title {
      display: block;
      margin-bottom: 2.5rem;
      font-size: 2rem;
      color: #fff;
      font-weight: 600;
      line-height: 1.1875;
      font-family: "Pretendard";
    }
    .list-detail {
      font-size: 1.25rem;
      color: #fff;
      line-height: 1.5;
      font-family: "Pretendard";
      white-space: pre-wrap;
    }
  }
}

.history {
  background-image: url("../../assets/images/bg/main_history_1.png"),
    url("../../assets/images/bg/main_history_2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: right top 19.875rem, left 2.6875rem bottom 13.3125rem;

  .title {
    margin-bottom: 6rem;
    font-size: 3.75rem;
    color: #fff;
    text-align: center;
  }

  .history-group {
    position: relative;

    .blur {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 30.6875rem;
      bottom: -1px;
      background-image: linear-gradient(
        to bottom,
        rgba(29, 33, 46, 0) 13%,
        #1d212e 92%
      );
      pointer-events: none;
    }

    .history-box {
      position: relative;
      max-width: calc(82.875rem + 3.3125rem);
      width: 100%;
      margin: auto;

      .history-list {
        position: relative;
        height: 100%;
        // max-height: calc(87.625rem - 29.75rem);
        max-height: 40.75rem;
        overflow: hidden;

        .history-item {
          position: relative;
          // height: auto;
          display: flex;
          align-items: flex-start;
          margin-left: 4.625rem;
          padding: 4.25rem 5.625rem;
          border-radius: 0.9375rem;
          -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
          border: solid 1px rgba(255, 255, 255, 0.2);
          background: rgba(53, 57, 69, 0.7);
          transition: 0.3s ease;
          font-family: "Pretendard";
          font-weight: 300;

          .year {
            font-size: 3.75rem;
            line-height: 1.083;
            color: #5f5aff;
            font-weight: 600;
            font-family: "Pretendard";
          }

          .contents {
            .text {
              color: #fff;
              white-space: pre-wrap;

              p {
                margin-bottom: 1rem;
                // font-size: 1.5rem;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

.value {
  padding-top: 3.4375rem;
  background-image: url("../../assets/images/bg/main_core_value_1.png"),
    url("../../assets/images/bg/main_core_value_2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: left center, left 2.6875rem bottom;
  background-size: 100%;

  .title {
    margin-bottom: 6.25rem;
    font-size: 3.75rem;
    color: #fff;
    text-align: center;
  }

  .value-list {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;
    color: #fff;
    gap: 1.6875rem;

    li {
      width: calc((100% - 3.375rem) / 3);
      max-height: 28.5rem;
      padding: 5rem 2.4375rem 4.0625rem;
      min-height: 23.875rem;
      overflow: hidden;
      white-space: pre-line;
      text-align: center;
      border-radius: 3rem;
      border: solid 1px rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgba(53, 57, 69, 0.7);

      strong {
        display: block;
        margin-bottom: 4.0625rem;
        font-size: 2.5rem;
        line-height: 1.325;
        color: #5f5aff;
        font-weight: 500;
      }
      p {
        font-size: 1.25rem;
        line-height: 1.6;
        word-break: keep-all;
        font-family: "Pretendard";
        letter-spacing: -0.1px;
      }
    }
  }
}

.media-kit {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  padding: 12.875rem 0 0;
  background: url("../../assets/images/bg/main_media-kit.png") no-repeat top 42%
    center / 100% auto;

  .title {
    margin-bottom: 6.1875rem;
    font-size: 3.75rem;
    color: #fff;
    text-align: center;
  }

  .media-kit-card {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    padding: 4.375rem 0 4.3125rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(53, 57, 69, 0.7);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    width: 13.125rem;
    height: 3.8125rem;
    margin: 0 1rem 0 0;
    color: #fff;
    font-size: 1.5rem;
    border-radius: 7.8125rem;
    line-height: 1.2;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      to right,
      #e6e9e9 0%,
      rgb(101 101 101 / 37%) 0%
    );
    transition: opacity 0.2s ease-out;
    font-family: "Pretendard";

    &.en-btn {
      width: 24.125rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

// common
.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .solution {
    padding: 93px 0 100px;

    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }

  .partners {
    .title {
      line-height: 79px;
    }
    .sub-title {
      margin-bottom: 100px;
      line-height: 29px;
    }
  }

  .awards {
    .title {
      line-height: 79px;
      margin-bottom: 99px;
    }
  }

  .history {
    .title {
      line-height: 79px;
      margin-bottom: 100px;
    }
  }

  .value {
    padding-top: 85px;
    line-height: 79px;
  }
  .media-kit {
    line-height: 79px;

    .title {
      margin-bottom: 99px;
    }

    .media-kit-card {
      padding: 69px 0 68px;
    }
  }

  .bg-area {
    padding-bottom: 202px;
  }
}

@media screen and (max-width: 1536px) {
  .intro {
    .inner {
      padding: 0 0 7.375rem;
    }
    &-en-text {
      font-size: 4.375vw;
    }
    &-ko-text {
      font-size: 4.1667vw;
    }
  }
  .history {
    .history-box {
      max-width: 100%;
      .history-list {
        .history-item {
          width: auto;
          padding: 3.5417vw 4.6875vw;
        }
      }
    }
  }
  .awards {
    padding: 7.3125rem 4.25rem;
  }
  .value {
    padding: 4rem 1.5rem 0;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.75rem;
    }
    .value-list {
      gap: 0.5rem;
      li {
        width: calc((100% - 1rem) / 3);
        max-height: 20rem;
        padding: 4.1875rem 1.9375rem;
        strong {
          margin-bottom: 2.625rem;
          font-size: 2.0625rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .media-kit {
    margin-top: -1px;
    padding: 4rem 1.5rem;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.75rem;
    }
    .media-kit-card {
      padding: 6.9271vw 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    .inner {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    button {
      width: 7.3125rem;
      font-size: 1rem;
      height: 2.375rem;
    }
  }
  .solution {
    height: 100%;
    padding: 4rem 1.5rem;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.75rem;
    }
    &-box {
      height: 100%;
      max-height: 28.125rem;

      + .solution-box {
        margin-top: 1.25rem;
      }

      .bg-pola {
        width: auto;
        height: 65%;
      }
      .bg-tivv {
        width: auto;
        height: 65%;
      }
      .sub-text {
        font-size: 1rem;
      }
      p {
        // margin-bottom: 1.5rem;
        font-size: 1.5rem;

        + p {
          font-size: 1rem;
        }
      }
    }
    .btn-view {
      width: 7.75rem;
      height: 2.125rem;
      font-size: 1rem;
    }
  }
  .partners {
    padding: 4rem 0;
    .title {
      margin-bottom: 1.5rem;
      font-size: 2.75rem;
    }
    .sub-title {
      margin-bottom: 2.5rem;
      font-size: 1rem;
    }
    .marquee-wrapper {
      row-gap: 1rem;
      li {
        width: 10.5625rem;
        height: 4.3125rem;
        margin: 0 0.3125rem;
      }
    }
  }
  .awards {
    padding: 4rem 1.5rem;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.75rem;
    }
    .swiper-slide {
      .trophy-image {
        height: 8.875rem;
        background-size: auto 100%;
      }
    }
    .list-box {
      height: 13rem;
      margin-top: 6.1875rem;
      padding: 4rem 2.0625rem 3.625rem;
      .list-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      .list-detail {
        font-size: 1rem;
      }
    }
  }
  .history {
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.75rem;
    }
    .history-group {
      padding: 0 1.5rem;
      .history-box {
        .history-list {
          .history-item {
            display: flex;
            padding: 2.625rem 4.5rem;
            .year {
              font-size: 3.5rem;
            }
            .contents {
              .text {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .bg-area {
    padding: 4rem 0 0;
  }
}
@media screen and (max-width: 820px) {
  .solution {
    &-box {
      padding-bottom: 7.6875rem;
      .bg-pola {
        width: auto;
        height: 62%;
        top: 9.625rem;
      }
      .bg-tivv {
        width: auto;
        height: 65%;
        top: 8.125rem;
        right: 2.925rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .intro {
    .inner {
      padding: 0 1.5rem 5.5rem;
    }
    &-text {
      br {
        display: block;
      }
    }
    &-en-text {
      font-size: 2rem;

      > div:nth-of-type(even) {
        margin-left: 0.5rem;
      }
    }
    &-ko-text {
      margin-top: 1rem;
      font-size: 1.125rem;
    }
  }
  .solution {
    height: auto;
    padding-top: 3rem;
    background-size: auto 100%;
    background-position: left 37% center;
    .title {
      margin-bottom: 0;
      font-size: 2.5rem;
    }
    &-box {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 3rem 2rem;
      backdrop-filter: none;
      box-shadow: none;
      border-radius: 15.8125rem 0 0 0;
      + .solution-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        // margin-top: 0;
      }
    }
    .bg-pola {
      z-index: 1;
      order: 1;
      position: relative;
      width: 100%;
      height: auto;
      bottom: 9.5rem;
      right: 0;
      top: unset;
    }
    .bg-tivv {
      z-index: 1;
      order: 1;
      position: relative;
      width: 100%;
      height: auto;
      bottom: 9.5rem;
      right: 0;
      top: unset;
    }
    .sub-text {
      font-size: 0.9375rem;
    }
    p {
      margin-bottom: 0;
      font-size: 1.5rem;
      + p {
        width: auto;
        word-break: keep-all;
        font-size: 0.9375rem;
      }
    }
    .btn-view {
      z-index: 2;
      position: relative;
      flex-shrink: 0;
      margin-top: 1.5rem;
      width: 6.25rem;
      height: 2.1875rem;
      font-size: 0.75rem;
    }
  }
  .partners {
    padding: 3rem 0;
    .title {
      margin-bottom: 1.125rem;
      font-size: 2rem;
    }
    .sub-title {
      margin-bottom: 2.5rem;
      font-size: 0.9375rem;
    }
    .marquee-wrapper {
      row-gap: 2rem;
      li {
        width: 12.875rem;
        height: 7rem;
      }
    }
  }
  .awards {
    padding: 0 0 3rem;
    background-size: auto 50%, auto 60%;
    background-position: top left, top 25% right;
    .title {
      margin-bottom: 1.5rem;
      font-size: 2rem;
    }
    .swiper-slide {
      .trophy-image {
        width: 6.125rem;
        height: 7.25rem;
        background-size: auto 100%;
      }
    }
    .list-box {
      // height: 10.75rem;
      padding: 2rem 1.5rem 1.25rem;
      .list-title {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        line-height: 1.2;
        word-break: keep-all;
      }
      .list-detail {
        font-size: 0.9375rem;
      }
    }
  }
  .history {
    .title {
      margin-top: -1px;
      margin-bottom: 1.5rem;
      font-size: 2rem;
    }
    .history-group {
      .history-box {
        // padding: 0 1.5rem;
        .history-list {
          max-height: calc(49.375rem);
          .history-item {
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% - 2.25rem);
            margin-left: 2.25rem;
            padding: 1.25rem;
            .year {
              margin-bottom: 1.5rem;
              font-size: 2.5rem;
            }
            .contents {
              .text {
                font-size: 0.9375rem;
              }
            }
          }
        }
      }
    }
  }
  .value {
    padding: 1rem 0 3rem;
    .title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .value-list {
      padding: 0 1.5rem;
      li {
        width: 100%;
        padding: 2.5rem 1.4375rem;
        border-radius: 1rem;
        min-height: 100%;
        strong {
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }
        p {
          font-size: 0.9375rem;
        }
      }
    }
  }
  .media-kit {
    margin-top: -1px;
    padding: 0 1.5rem;
    .title {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .media-kit-card {
      flex-direction: column;
      padding: 3rem 0;
      row-gap: 0.75rem;
      border-radius: 1rem;
    }
    button {
      width: 9.125rem;
      height: 2.6875rem;
      margin: 0;
      font-size: 1rem;

      &.en-btn {
        width: 16.125rem;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .solution {
    .bg-pola {
      height: 100%;
      bottom: 1.5rem;
    }
    .bg-tivv {
      height: 100%;
      bottom: 0.5rem;
    }
  }
}
