.menubar-wrapper {
  flex-shrink: 0;
  width: 12.625rem;
  height: calc(100vh - 3.75rem);
  box-shadow: 0 0px 0.9375rem 0 rgba(19, 17, 17, 0.32);
}
.menubar-list {
  height: 100%;
  max-height: calc(100vh - 3.75rem);
  background-color: #f8f9fa;
  overflow: hidden;
  overflow-y: auto;

  .menu-box {
    a {
      display: block;
      padding: 1.5rem 1.75rem 1.5rem 2rem;
      cursor: pointer;
    }

    &.active {
      position: relative;
      background-color: #fff;

      a {
        padding-bottom: 1rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.25rem;
        height: 100%;
        background-color: var(--color-green);
      }

      .menu-item {
        background-image: url("../../../assets/images/ico/ico_accordion_arrow_active.png");
      }

      .menu-item.last-item {
        padding-left: 1rem;

        a {
          padding: 1.5rem 1.75rem 1.5rem 2rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 1.75rem;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          background: url("../../../assets/images/ico/icon_current_page.png")
            no-repeat center center;
        }
      }
    }

    .menu-item {
      background: url("../../../assets/images/ico/ico_accordion_arrow.png")
        no-repeat right 1.5rem center / 0.75rem 0.375rem;
      font-weight: 500;

      &.last-item {
        background-image: none;
      }
    }
  }
}

.accordion-box {
  margin-top: -0.125rem;
  padding-bottom: 0.5rem;

  .accordion-item {
    margin-top: 0.625rem;

    a {
      position: relative;
      display: block;
      color: var(--color-gray);
      padding: 0.625rem 0rem 0.625rem 2.875rem;

      &.active {
        color: var(--color-green);

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 1.75rem;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          background: url("../../../assets/images/ico/icon_current_page.png")
            no-repeat center center;
        }
      }
    }
  }
}

.copyright-wrap {
  width: 200px;
  height: 17px;
  position: absolute;
  bottom: 24px;
  left: 24px;

  .copyright {
    font-size: 12px;
    font-weight: normal;
    color: var(--color-border-gray);
  }
}
