$delay-time: (0.05s, 0.1s, 0.15s, 0.2s, 0.25s, 0.3s, 0.35s, 0.4s, 0.45s);

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  row-gap: 0.5rem;
  background: url("../../assets/images/bg/pola_intro.png") no-repeat;
  background-size: cover;
  background-position: top 35% center;

  .inner {
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
    margin-top: -13vh;
    padding: 0 1.5rem;
    // padding: 0 13.5417vw;
    // padding-top: 16.6875rem;
  }

  &-text {
    flex-shrink: 0;
    white-space: nowrap;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.25;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          display: inline-block;
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }
  }
  &-en-text {
    font-size: 6.5625rem;
    font-weight: 700;
    line-height: 1.3142;
    text-transform: uppercase;
  }
  &-ko-text {
    margin-top: 0.6875rem;
    font-size: 1.8125rem;
    line-height: 1.2;
    font-family: "Pretendard";
    font-weight: 400;
  }

  .btn-group {
    flex-wrap: wrap;
    row-gap: 0.5rem;
    margin-top: 3.75rem;
    column-gap: 1.5625rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 12.3125rem;
    white-space: nowrap;
    padding: 0 3.57rem;
    height: 4.6875rem;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    background-color: #5b54fe;
    border-radius: 999px;
    box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
    font-family: "Pretendard";
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.service {
  position: relative;
  padding: 7.5rem 0 5.8125rem;
  opacity: 0.73;
  background-image: linear-gradient(to top, #cad3e6 1%, #eef3ff 99%);

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg/pola_service_1.png") no-repeat left
      bottom / auto 100%;
  }
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg/pola_service_2.png") no-repeat right
      bottom / auto 100%;
  }

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3125;
    text-align: center;
  }

  .sub-title {
    display: block;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-family: "Pretendard";
  }

  &-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75.625rem;
    height: 41.5rem;
    margin: -0.875rem auto 0;

    .gif-item {
      z-index: 1;
      position: absolute;
      width: auto;
      height: 100%;

      &:nth-of-type(1) {
        left: 0.9063rem;
      }
      &:nth-of-type(2) {
        right: -6.9063rem;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      max-width: 58rem;
      width: 100%;
      margin: 0 auto;
    }

    li {
      width: 100%;
      padding: 1.375rem 5.375rem 1.625rem;
      text-align: center;
      font-size: 1.5rem;
      color: #0a1434;
      font-weight: 700;
      line-height: 1.208;
      border-radius: 999px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      font-family: "Pretendard";
    }
  }
}

// .gradient-bg-section {
//   padding: 7.5rem 0 10rem;
// }

.application {
  max-width: 87.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 7.5rem 0 5.1825rem;

  .title {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1875;
    text-align: center;
    font-family: "Pretendard";
  }

  &-box {
    margin-top: 17.5625rem;

    ul {
      column-gap: 1rem;
      row-gap: 7.75rem;
      justify-content: center;
    }

    li {
      position: relative;
      width: calc((100% - 3rem) / 4);
      height: 25.125rem;
      padding: 8.875rem 0.6875rem 0;
      border-radius: 12.25rem 0 0 0;
      box-shadow: 0px 9px 14px -3px rgba(0, 0, 0, 0.12);
      background-color: rgb(255, 255, 255, 0.7);

      .img-box {
        position: absolute;
        top: -5.4375rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12.25rem;
        height: 12.25rem;
        border-radius: 50%;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 1px 0 20px 0 rgba(91, 84, 254, 0.1);
        border: solid 2px rgba(229, 230, 238, 0.56);
        background-color: #fff;
        background-image: linear-gradient(to top, #ededed, #fff);

        &::before {
          content: "";
          position: absolute;
          bottom: -1.4375rem;
          width: 3.25rem;
          height: 0.1875rem;
          opacity: 0.19;
          -webkit-filter: blur(2px);
          filter: blur(2px);
          background-color: #000;
        }
      }

      strong {
        display: block;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.208;
        text-align: center;
        font-family: "Pretendard";
      }

      p {
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: center;
        white-space: pre-wrap;
        font-family: "Pretendard";
        word-break: keep-all;
      }
    }

    .desc-text {
      display: block;
      margin-top: 2.5rem;
      font-size: 1.5rem;
      text-align: center;
      font-family: "Pretendard";
      line-height: 1.21;
      color: #000;
    }
  }

  &.en-application {
    padding-top: 7.5rem;
  }
}

.advantage {
  padding: 10rem 0;

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1875;
    text-align: center;
    font-family: "Pretendard";
  }

  .advantage-list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
  }

  .advantage-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 28rem;
    border-radius: 3rem;
    overflow: hidden;
    background-color: #fff;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .img-box {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -1px;
          width: 35.7%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
    }

    .img-box {
      position: relative;
      width: 51.21%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        width: 35.7%;
        height: 100%;
        background-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-box {
      width: 48.79%;
      padding: 0 4.375rem;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .num {
        display: block;
        font-size: 3.25rem;
        line-height: 1.1923;
        color: #8d96ab;
        font-family: "Pretendard";
      }
      .content-title {
        display: block;
        margin: 0.75rem 0 3rem;
        font-size: 3rem;
        line-height: 1.208;
        font-weight: 600;
        color: #5f5aff;
        font-family: "Pretendard";
      }
      .content-text {
        font-size: 1.5rem;
        line-height: 1.5;
        font-family: "Pretendard";
      }
    }
  }
}

.bg-area {
  padding: 10rem 0;
}

.process {
  color: #fff;

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1875;
    text-align: center;
    font-family: "Pretendard";
  }

  .process-list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
  }
  .process-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 28rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.24);
    background: rgba(53, 57, 69, 0.7);
    overflow: hidden;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .img-box {
      position: relative;
      width: 49.28%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-box {
      width: 50.72%;
      padding: 0 4.375rem;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .num {
        display: block;
        font-size: 3.25rem;
        line-height: 1.1923;
        color: #8d96ab;
        font-family: "Pretendard";
      }
      .content-title {
        display: block;
        margin: 0.75rem 0 3rem;
        font-size: 3rem;
        line-height: 1.208;
        font-weight: 600;
        color: #5f5aff;
        font-family: "Pretendard";
      }
      .content-text {
        font-size: 1.5rem;
        line-height: 1.5;
        font-family: "Pretendard";
        letter-spacing: -0.02em;
      }
    }
  }
}

.faq {
  padding-top: 10rem;
  max-width: 87.5rem;
  width: 100%;
  margin: 0 auto;
  color: #fff;

  .title {
    margin-bottom: 6rem;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.3194;
    text-align: center;
  }

  &-box {
    padding: 0 3rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.24);
  }

  &-list {
    padding: 3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    &:hover {
      cursor: pointer;
    }

    .faq-img {
      display: block;
      width: 5rem;
      height: 4rem;
      margin-left: auto;
    }
  }

  .question-item {
    column-gap: 2.5625rem;
    color: #c2bfff;
    font-weight: 500;

    &.active {
      color: #5f5aff;

      .text {
        font-size: 1.75rem;
      }
    }

    .num {
      font-size: 3rem;
      font-weight: 500;
      line-height: 1.31;
    }
    .text {
      font-size: 1.5rem;
      font-family: "Pretendard";
    }
  }

  .answer-list {
    display: none;
    margin-top: 2.5625rem;

    &.active {
      display: block;
    }
  }

  .answer-item {
    display: flex;
    align-items: flex-start;
    column-gap: 2.5rem;
    color: #fff;
    font-weight: 500;

    .content-box {
      width: calc(100% - 3.375rem);
    }

    .num {
      font-size: 3rem;
    }
    .text {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.3541;
      font-family: "Pretendard";
      word-break: keep-all;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
      max-height: 31.25rem;
      margin-top: 1rem;
      object-fit: contain;
    }
  }
}

// common
.gradient-bg-section {
  background-image: linear-gradient(to top, #ededed, #fff);
}

.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
  font-family: "Pretendard";
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// response
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .intro {
    background-size: cover;
  }
  .service {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .advantage {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .process {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .faq {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .application {
    &-box {
      ul {
        padding: 0 1.5rem;
      }
    }
  }

  .advantage {
    .advantage-list {
      padding: 0 1.5rem;
    }
  }

  .process {
    .process-list {
      padding: 0 1.5rem;
    }
  }

  .faq {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    &-en-text {
      font-size: 2.5rem;
    }
    &-ko-text {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
    .btn-group {
      margin-top: 2.4375rem;
      column-gap: 0.8125rem;
    }
    button {
      // width: 6.5625rem;
      height: 2.5rem;
      font-size: 0.75rem;
    }
  }

  .service {
    padding: 3.9375rem 0 2.8125rem;

    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }

    .sub-title {
      font-size: 1rem;
    }

    &-box {
      max-width: 38.625rem;
      height: 20.5625rem;

      .gif-item {
        width: 6.75rem;
        height: auto;

        &:nth-of-type(1) {
          left: -4.1875rem;
        }
        &:nth-of-type(2) {
          width: 13.75rem;
          right: -7.1875rem;
        }
      }

      ul {
        row-gap: 0.625rem;
      }
      li {
        padding: 0.6875rem 5.3125rem;
        font-size: 1rem;
      }
    }
  }

  .gradient-bg-section {
    padding: 0 0 2.8125rem;

    .title {
      font-size: 2.5rem;
    }
  }
  .application {
    &-box {
      margin-top: 11.4375rem;

    li {
      height: 16.375rem;
      padding-top: 6.1875rem;
      padding-bottom: 1rem;
      border-radius: 9.375rem 0 0 0;

      .img-box {
        width: 8.5rem;
        height: 8.5rem;
        
        img {
          display: block;
          width: 85%;
          height: auto;
        }
      }
      
      strong {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
  
      p {
        font-size: 1rem;
      }
    }

    }

    .desc-text {
      margin-top: 1.625rem;
      font-size: 0.875rem;
    }
  }

  .advantage {
    padding: 4rem 0 0;

    .title {
      margin-bottom: 2.5rem;
    }

    .advantage-item {
      height: 19.5625rem;

      .img-box {
        width: 49.21%;
      }

      .content-box {
        width: 50.79%;
        padding: 0 3.0625rem;
        .num {
          font-size: 2.25rem;
        }
        .content-title {
          font-size: 2.0625rem;
        }
        .content-text {
          font-size: 1rem;
          letter-spacing: -0.06em;
        }
      }
    }
  }

  .bg-area {
    padding: 4rem 0;
  }

  .process {
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
    }

    .process-list {
      row-gap: 1.5rem;
    }

    .process-item {
      height: 19.5625rem;

      .img-box {
        width: 49.21%;
      }

      .content-box {
        width: 50.79%;
        padding: 0 3.0625rem;
        .num {
          font-size: 2.25rem;
        }
        .content-title {
          font-size: 2.0625rem;
        }
        .content-text {
          font-size: 1rem;
          letter-spacing: -0.06em;
        }
      }
    }
  }

  .faq {
    padding-top: 4rem;

    .title {
      margin-bottom: 2.5rem;
      font-size: 3rem;
    }

    &-box {
      padding: 0 2.125rem;
    }

    &-list {
      padding: 2.125rem 0 1.5rem;

      .faq-img {
        width: 3.4375rem;
        height: 2.875rem;
      }
    }

    .question-item {
      &.active {
        .text {
          font-size: 1.125rem;
        }
      }

      .num {
        font-size: 2rem;
      }
      .text {
        font-size: 0.875rem;
      }
    }

    .answer-list {
      .num {
        font-size: 2rem;
      }
      .text {
        font-size: 0.875rem;
      }
    }
  }

  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin-top: 0;
    }

    &-en-text {
      font-size: 3rem;

      > div:nth-of-type(even) {
        margin-left: 0.5rem;
      }
    }
    &-ko-text {
      margin-top: 0.75rem;
      font-size: 1rem;
    }

    .btn-group {
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: center;
    }

    button {
      // width: 7.75rem;
      height: 2.75rem;
      font-size: 1rem;
    }
  }

  .service {
    padding: 3.25rem 0 3rem;

    .title {
      width: 100%;
      font-size: 1.75rem;
      font-weight: 700;
      word-break: keep-all;
    }

    .sub-title {
      font-size: 0.875rem;
    }

    &-box {
      height: auto;
      margin-top: 3rem;
      padding: 0 1.5rem;

      .gif-item {
        display: none;
      }

      li {
        padding: 1rem 2.4375rem;
        font-size: 0.875rem;
        line-height: 1.14;
        word-break: keep-all;
      }
    }
  }

  .gradient-bg-section {
    background-color: #f3f3f3;

  }
  .advantage {
    padding: 2.5rem 0 3rem;

    .title {
      font-size: 1.75rem;
    }

    .advantage-list {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem;
      max-width: 87.5rem;
      width: 100%;
      margin: 0 auto;
    }

    .advantage-item {
      flex-direction: column;
      height: 100%;
      border-radius: 1rem;

      &:nth-child(even) {
        flex-direction: column;
      }

      .img-box {
        width: 100%;

        &::before {
          display: none;
        }

        img {
          height: 10rem;
        }
      }

      .content-box {
        width: 100%;
        padding: 1.5rem 1rem;

        .num {
          display: inline-block;
          font-size: 0.9375rem;
        }
        .content-title {
          display: inline-block;
          margin: 0 0 0.75rem 0.5rem;
          font-size: 0.9375rem;
          white-space: nowrap;
        }
        .content-text {
          font-size: 0.9375rem;
        }
      }
    }
  }
  .application {
    padding: 3.25rem 0 3rem;
  
    .title {
      margin-bottom: 7rem;
      font-size: 1.75rem;
    }

    &-box {
      margin-top: 4.625rem;

      ul {
        column-gap: 0.5rem;
        row-gap: 4.125rem;
      }

      li {
        width: calc((100% - 0.5rem) / 2);
        height: 13.125rem;
        padding: 4.625rem 0.5rem 0;
        border-radius: 8.9375rem 0 0 0;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
        box-shadow: 1px 0 20px 0 rgba(91, 84, 254, 0.1);
        background-color: #fff;

        .img-box {
          top: -3.375rem;
          width: 6.25rem;
          height: 6.25rem;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        strong {
          margin-bottom: 0.75rem;
          font-size: 0.75rem;
        }

        p {
          font-size: 0.9375rem;
        }
      }

      .desc-text {
        // margin-top: 0.75rem;
        padding: 0 1.5rem;
        // display: none;
        font-size: 1rem;
      }
    }
  }

  .process {
    padding: 0 1.5rem;

    .title {
      font-size: 1.75rem;
      margin-bottom: 1.75rem;
    }

    .process-list {
      padding: 0;
    }

    .process-item {
      flex-direction: column;
      height: 100%;
      border-radius: 1rem;

      &:nth-child(even) {
        flex-direction: column;
      }

      .img-box {
        width: 100%;
        height: 10rem;
      }

      .content-box {
        width: 100%;
        padding: 1.5rem 1rem;

        .num {
          display: inline-block;
          font-size: 0.9375rem;
        }
        .content-title {
          display: inline-block;
          margin: 0 0 0.75rem 0.5rem;
          font-size: 0.9375rem;
        }
        .content-text {
          font-size: 0.9375rem;
        }
      }
    }
  }

  .faq {
    padding: 3rem 1.5rem 0;

    .title {
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
    }

    &-box {
      padding: 0 0.5rem 0 0.75rem;
      border-radius: 1rem;
    }

    &-list {
      padding: 1.5rem 0;

      .faq-img {
        width: 2.5rem;
        height: 2rem;
      }
    }

    .question-item {
      column-gap: 0.375rem;

      .num {
        font-size: 0.9375rem;
      }
      .text {
        font-size: 0.9375rem;
      }
    }

    .answer-list {
      margin-top: 1.5rem;
    }

    .answer-item {
      align-items: baseline;
      column-gap: 0.375rem;

      .num {
        font-size: 0.9375rem;
      }
      .text {
        font-size: 0.9375rem;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
