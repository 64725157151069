// @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./src/assets/scss/reset.scss";
@import "./src/assets/scss/fonts.scss";

:root {
  --bg-white: #ffffff;

  --color-white: #e8e8e8;
  --color-border-gray: #cbd5e0; // boder 색상
  --color-gray: #a0aec0; //hover전 폰트색상 , 배경 색상
  --color-bg-light-gray: #f8f9fa;
  --color-bottom-gray: #e8e8e8;
  --color-light-gray: #eff6ff;
  --color-font-gray: #2d3748;

  --color-green: #58b095;
  --color-light-green: #ecfff9;

  --color-red: #eb5d75;
  --color-main: #376eb6;
}

* {
  box-sizing: border-box;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}
ol,
ul,
dl {
  margin: 0;
  padding: 0;

  &::marker {
    content: none;
  }
}

input {
  border: none;
  outline: none;
}

button {
  padding: 0;
  outline: none;
  border: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

html {
  // overflow-y: hidden;

  // @media screen and (max-width: 1536px) {
  //   font-size: 14px;
  // }
  // @media screen and (max-width: 1024px) {
  //   font-size: 16px;
  // }
}

body {
  width: 100vw;
  font-family: Roboto, NanumSquare, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  cursor: default;
  overflow: hidden;
  overflow-y: auto;
  color: #1d212e;
}

html,
body {
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: 1536px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

a {
  color: inherit;
  text-decoration: none;
}
