.banner {
  z-index: 11;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  margin-top: -1px;
  background-color: #2a2a2a;

  &-content {
    padding: 0 1rem;
    font-size: 1.125rem;
    color: #fff;
  }
  .btn-delete-banner {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    font-size: 1.125rem;
    color: #fff;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1024px) {
  // .banner {
  //   top: 3.625rem;
  // }
}
@media screen and (max-width: 768px) {
  .banner {
    display: none;
  }
}
