.btn-group {
  // margin-top: 1rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 2.625rem;
    padding: 0.5rem 0.75rem;
    color: #fff;
    border-radius: 0.375rem;
    background-color: var(--color-green);
    white-space: nowrap;
  }
}
