.addInfo-wrapper {
  // ul
  margin: 0.5rem 0 1rem;
  // max-height: 36.875rem;
  overflow-y: auto;

  // 스크롤바 전체
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  // 스크롤 막대
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray);
    border-radius: 999px;
  }
  // 스크롤 막대 외부
  &::-webkit-scrollbar-track {
    background-color: #e2e8f0;
    border-radius: 999px;
  }
  table {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid var(--color-border-gray);
    border-bottom: 0;
  }
  tr {
    display: flex;
    border-bottom: 1px solid var(--color-border-gray);
  }
  th {
    // display: flex;
    // align-items: center;
    flex-shrink: 0;
    width: 9.375rem;
    padding: 1rem 1.5rem;
    text-align: left;
    background-color: var(--color-bg-light-gray);
  }
  td {
    width: 100%;
    padding: 1rem 1.5rem;
    text-align: left;

    .contents {
      min-height: 3rem;
      max-height: 14.375rem;
      overflow: hidden;
      overflow-y: auto;

      // 스크롤바 전체
      &::-webkit-scrollbar {
        width: 0.5rem;
      }
      // 스크롤 막대
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray);
        border-radius: 999px;
      }
      // 스크롤 막대 외부
      &::-webkit-scrollbar-track {
        background-color: #e2e8f0;
        border-radius: 999px;
      }
    }

    label {
      display: inline-block;
      border: 1px solid var(--color-border-gray);
      line-height: 1.5;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      width: max-content;
    }

    input {
      width: 100%;

      &[type="date"] {
        display: block;
        width: 9.5%;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .urlBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
    .urlBtn {
      background-color: var(--color-green);
      width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 20px;
      transition: 0.5s;
      &:hover {
        opacity: 75%;
      }
    }
  }
}

.file-list {
  td {
    display: flex;
    flex-direction: column;
    // row-gap: 0.5rem;
    input {
      width: 15%;
    }
  }
  .files {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    width: 100%;
    max-height: 213px;
    height: 100%;
    // padding-top: 0.625rem;
    overflow-x: hidden;
    overflow-y: auto;

    // 스크롤바 전체
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    // 스크롤 막대
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray);
      border-radius: 999px;
    }
    // 스크롤 막대 외부
    &::-webkit-scrollbar-track {
      background-color: #e2e8f0;
      border-radius: 999px;
    }

    .file-box {
      position: relative;
      width: max-content;
      margin-top: 0.75rem;
      padding-bottom: 0.5rem;

      .btn-delete {
        position: absolute;
        top: -11px;
        right: -14px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          margin-bottom: 0;
        }
      }

      img {
        display: block;
        width: 150px;
        height: 150px;
        margin-bottom: 0.5rem;
        object-fit: cover;
      }
    }

    .file {
      flex-grow: 0;
      display: inline-block;
      width: 6.75rem;
      padding: 0.5rem 1.25rem;
      border: 1px solid var(--color-border-gray);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.dimmed {
  opacity: 0.5; /* 투명도 조절 */
  background-color: gray; /* 배경색을 회색으로 변경 */

  input {
    opacity: 0.5; /* 투명도 조절 */
    background-color: gray; /* 배경색을 회색으로 변경 */
    pointer-events: none;
  }
}
