.dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: transparent;
  mix-blend-mode: normal;
  background-color: rgba(128, 128, 128, 0.5); /* 배경색을 회색으로 변경 */
}

.list-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 1.25rem;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(3.125rem);
  backdrop-filter: blur(3.125rem);
  border: solid 1px var(--color-border-gray);
  background-color: #fff;
  transform: translate(-50%, -50%);

  .list-header {
    padding: 1rem;
    text-align: center;
    background-color: var(--color-bg-light-gray);
    border-radius: 0.25rem;
    color: var(--color-gray);
  }
  .content-list-item {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    padding: 0 1rem;
    border-bottom: 1px solid var(--color-border-gray);

    .list-item {
      padding: 1rem 0;
      text-align: center;

      input {
        width: 100%;
      }
    }
  }
}
