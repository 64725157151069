.green-box {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  min-width: 360px;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  border: 1px solid var(--color-green);
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 1.5rem 0 rgba(19, 17, 17, 0.32);
  background-color: #ffffff;
  white-space: pre-line;
  text-align: center;
}

.red-box {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  width: 360px;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  border: 1px solid var(--color-red);
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 1.5rem 0 rgba(19, 17, 17, 0.32);
  background-color: #ffffff;
  white-space: pre-line;
  text-align: center;
}

.dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: transparent;
  mix-blend-mode: normal;
  opacity: 0.3;
}

.modal-text {
  font-size: 14px;
  font-weight: 500;
  padding: 24px 0 32px 0;
}

// 각 모달 부분의 이미지
.img-wrap {
  width: 56px;
  height: 56px;
  margin: 24px auto 0;
  .save {
    width: 56px;
    height: 56px;
    background: url("../../assets/images/ico/save.png") no-repeat center center;
  }

  .warning {
    width: 56px;
    height: 56px;
    background: url("../../assets/images/ico/warning.png") no-repeat center
      center;
  }
}

// 버튼이 하나일 때
.btn-box {
  padding: 0 1.25rem 1rem 1.25rem;
  // width: 22.5rem;
  height: auto;
}
// 버튼이 두개일 때
.btn-group {
  display: flex;
  padding: 0 1.25rem 1rem 1.25rem;
}

.btn-group,
.btn-box {
  display: flex;
  column-gap: 16px;

  button {
    font-size: 0.875rem;
    color: var(--color-gray);
    width: 100%;
    flex: 1;
    height: 2.5rem;
    border-radius: 0.3125rem;

    &.confirm {
      border: 1px solid var(--color-green);
      background-color: var(--color-green);
      color: #fff;
    }

    &.warning {
      border: 1px solid var(--color-red);
      background-color: var(--color-red);
      color: #fff;
    }

    &.close {
      border: 1px solid var(--color-gray);
    }
  }
}
