.awards-list {
  position: relative;
  // width: 97.5rem;
  margin: 0 auto;
  padding: 0 7.5rem 3.125rem;
  max-width: 90rem;

  @media screen and (max-width: 1536px) {
    width: 91vw;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 0 1.25rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 1.5rem 1.25rem;
  }
}

.swiper {
  position: static;
  overflow: hidden;
}

.swiper-slide {
  position: relative;
  width: calc(100% - 4.625rem);
  height: 27.5625rem;

  @media screen and (max-width: 1024px) {
    height: 20.125rem;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
}

#main-history .swiper-slide {
  height: unset;
}

.swiper-button-next,
.swiper-button-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  top: calc(50% + 3rem);
  background-color: #ccc;
  border-radius: 50%;
}

.swiper-button-prev {
  left: 0;

  @media screen and (max-width: 1024px) {
    top: 14.5%;
    left: unset;
    right: 5rem;
  }
}
.swiper-button-next {
  right: 0;

  @media screen and (max-width: 1024px) {
    top: 14.5%;
    right: 0.5rem;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem;
  background-repeat: no-repeat;
  background-size: 0.5rem 1rem;
  color: #fff;
}

.swiper-button-prev::after {
  content: "";
  width: 4rem;
  height: 4rem;
  color: #fff;
  background-image: url("../../assets/images/button/btn_prev.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
.swiper-button-next::after {
  content: "";
  width: 4rem;
  height: 4rem;
  color: #fff;
  background-image: url("../../assets/images/button/btn_next.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  // width: 95.25rem;
  width: calc(100% - 4.625rem + 4.5rem);
  height: 0.1875rem;
  top: calc(100% - 3px);
  left: 0rem;

  @media screen and (max-width: 1536px) {
    width: calc(91vw - 9.5rem);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    top: calc(100% - 0.5rem);
    left: 0;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 3rem);
    top: calc(100% - 0.5rem);
    left: 1.5rem;
  }
}

.swiper-pagination-progressbar {
  background-color: #8d96ab;
  border-radius: 2px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #5b54fe;
  border-radius: 2px;
}

.history-item.swiper-slide {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -3.94rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../../assets/images/ico/ico_slide_default.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

    @media screen and (max-width: 1024px) {
      left: -3.15rem;
    }
    @media screen and (max-width: 768px) {
      left: -1.875rem;
      width: 1rem;
      height: 1rem;
    }
  }
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: -3.35rem;
    width: 3px;
    height: calc(101% + 0.625rem);
    background-color: #fff;

    @media screen and (max-width: 1024px) {
      left: -2.5rem;
      top: 1px;
    }
    @media screen and (max-width: 768px) {
      left: -1.5rem;
      width: 2px;
      height: calc(110% + 3.75rem);
    }
  }
}
.history-item.swiper-slide-active {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -4.56rem;
    width: 2.625rem;
    height: 2.625rem;
    background-color: #1d212e;
    background-image: url("../../assets/images/ico/ico_slide_current.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    border-radius: 50%;

    @media screen and (max-width: 1024px) {
      top: 0rem;
      left: -3.25rem;
      width: 1.8125rem;
      height: 1.8125rem;
    }
    @media screen and (max-width: 768px) {
      top: 2rem;
      left: -2.125rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
