$delay-time: (
  0.05s,
  0.1s,
  0.15s,
  0.2s,
  0.25s,
  0.3s,
  0.35s,
  0.4s,
  0.45s,
  0.5s,
  0.55s,
  0.6s,
  0.65s,
  0.7s
);

.intro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 43.75rem;
  row-gap: 0.5rem;
  background: url("../../assets/images/bg/people_intro.png") no-repeat top
    center / 100% auto;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 43.75rem;
  }

  &-text {
    flex-shrink: 0;
    white-space: nowrap;
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.25;
    white-space: pre-wrap;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          display: inline-block;
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }
  }
  &-main-text {
    margin-bottom: 2.125rem;
    font-size: 5rem;
    font-weight: 700;
    color: #5b54fe;
    text-transform: uppercase;
    font-family: "Pretendard";
  }
  &-ko-text {
    width: 100%;
    font-size: 1.5rem;
    color: #000;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: center;

    > div {
      display: inline-block;

      br {
        display: block;
      }
    }
  }

  .btn-group {
    column-gap: 1.5rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12.3125rem;
    height: 3.375rem;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    background-color: #5b54fe;
    border-radius: 999px;
    box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
    font-family: "Pretendard";
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.leaders {
  position: relative;
  padding: 7.5rem 0;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    text-align: center;
  }

  .sub-title {
    display: block;
    margin: 2.5rem 0 6rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-family: "Pretendard";
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.625rem;
    row-gap: 5.125rem;
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
  }
  &-item {
    display: flex;
    column-gap: 2rem;
    flex-shrink: 0;
    width: calc((100% - 1.625rem) / 2);

    .img-box {
      // min-width: 44.15%;
      flex-shrink: 0;
      // width: 100%;
      height: 11.25rem;
      border-radius: 1.5rem;
      border: solid 2px rgba(112, 112, 112, 0.3);
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .info-box {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #8d96ab;
    white-space: nowrap;
  }
  .info-name {
    font-size: 2.5rem;
    font-weight: 600;
    font-family: "Pretendard";
  }
  .info-rank {
    display: inline-block;
    margin-left: 1rem;
    font-size: 2.125rem;
    color: #5f5aff;
    font-weight: 500;
  }
  .career-box {
    font-size: 1rem;
    white-space: pre-wrap;
    line-height: 1.5625;
    letter-spacing: -0.02em;
    font-family: "Pretendard";
  }
}

.people {
  padding: 10rem 0 4rem;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;

    br {
      display: none;
    }
  }

  .sub-title {
    display: block;
    margin-bottom: 6rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }

  .people-list {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .people-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 100.875rem;
    width: 100%;
    height: 35.875rem;
    margin: 0 auto;
    border-radius: 3rem;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: left center;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    transform: translate(-3.1875rem, 0);

    &:nth-of-type(even) {
      text-align: right;

      .btn-show-popup {
        margin-left: auto;
      }
    }
    &:nth-of-type(2) {
      flex-direction: row-reverse;
      background-position: right center;
      transform: translate(3.1875rem, -2rem);
    }
    &:nth-of-type(3) {
      background-position: left center;
      transform: translate(-3.1875rem, -4rem);
    }
    &:nth-of-type(4) {
      flex-direction: row-reverse;
      background-position: right center;
      transform: translate(3.1875rem, -6rem);
    }

    .content-box {
      width: 45.35%;
      padding: 0 5.375rem;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .content-title {
        display: block;
        // height: 2.5rem;
        line-height: 1.37;
        margin: 0 0 1.9375rem;
        font-size: 2.5rem;
        font-weight: 600;
        color: #5f5aff;
      }
      .content-text {
        height: 4.5rem;
        margin-bottom: 6.625rem;
        font-size: 1.5rem;
        line-height: 1.5;
      }
      .btn-show-popup {
        position: relative;
        width: 7.5rem;
        height: 7.5rem;

        &.active {
          animation: rotateAni 0.4s ease-in-out forwards;
          // transform: rotate(45deg);
        }

        button,
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .list-popup {
    position: relative;
    display: block;
    height: 100%;
    background-color: red;
  }
  .popup-item {
    z-index: 1;
    position: absolute;
    width: max-content;
    padding: 2.5rem;
    font-size: 1.5rem;
    line-height: 1.6;
    border: 4px solid #5b54fe;
    border-radius: 1.5rem;
    white-space: pre-wrap;
    background-color: #fff;

    &.popup-1 {
      top: 21.9688rem;
      left: 64.33%;
    }
    &.popup-2 {
      top: 55.8438rem;
      left: 46.77%;
    }
    &.popup-3 {
      top: 89.8125rem;
      left: 64.33%;
    }
    &.popup-4 {
      top: 123.7813rem;
      left: 46.77%;
    }
  }
}

@keyframes rotateAni {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}

.photo {
  padding: 0 0 10rem;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    text-align: center;

    br {
      display: block;
    }
  }

  .img-box {
    max-width: 87.5rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 3rem;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .img-desc {
    display: block;
    margin-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-family: "Pretendard";
  }
}

.culture {
  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    color: #fff;
    text-align: center;
  }

  .sub-title {
    display: block;
    margin: 2.5rem 0 7.5rem;
    font-size: 1.5rem;
    color: #fff;
    line-height: 1.5;
    font-family: "Pretendard";
    text-align: center;
  }

  &-list {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    color: #fff;
  }
  &-item {
    padding: 9.375rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.24);
    background: rgba(53, 57, 69, 0.7);
  }
  .item-title {
    margin-bottom: 2.5rem;
    font-size: 3rem;
    color: #8a85ff;
    font-weight: 600;
    text-align: center;
    font-family: "Pretendard";
  }
  .item-desc {
    display: block;
    margin-bottom: 4rem;
    font-size: 1.5rem;
    line-height: 1.67;
    text-align: center;
    font-family: "Pretendard";
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17.5rem;
    height: 5.25rem;
    margin: 0 auto;
    font-family: "Pretendard";
    font-size: 2rem;
    line-height: 1.19;
    color: #fff;
    border-radius: 999px;
    background-color: #5b54fe;
  }
}

// common
.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  box-shadow: 0 1.5rem 2rem 0 rgba(24, 45, 112, 0.36);
  font-family: "Pretendard";
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.bg-section {
  padding: 10rem 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .leaders {
    .title {
      font-size: 60px;
      line-height: 79px;
    }
  }
  .people {
    .title {
      font-size: 60px;
      line-height: 79px;
    }
  }
  .photo {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .culture {
    font-size: 60px;
    line-height: 79px;
  }
}

@media screen and (max-width: 1536px) {
  .leaders {
    padding: 7.5rem 4.25rem;
  }
  .people {
    .popup-item {
      &.popup-1 {
        left: 64.8021vw;
      }
      &.popup-2 {
        top: 55.8438rem;
        left: 44.8021vw;
      }
      &.popup-3 {
        left: 64.8021vw;
      }
      &.popup-4 {
        left: 44.8021vw;
      }
    }
  }
  .photo {
    padding: 0 4.25rem 10rem;
  }
  .culture {
    // padding: 10rem 4.25rem;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 43.25rem;
      height: 38.5rem;
      padding: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .intro {
    height: 29.75rem;
    .inner {
      height: 29.75rem;
    }
  }
  .leaders {
    &-item {
      column-gap: 1.5rem;
      width: calc((100% - 1.625rem) / 2);

      .img-box {
        height: 8.25rem;
      }
    }
    .info-name {
      font-size: 2rem;
    }
    .info-rank {
      font-size: 1.8125rem;
    }
  }
  .people {
    .people-item {
      &:nth-of-type(odd) {
        transform: translate(-1.5938rem, 0);
      }
      &:nth-of-type(even) {
        transform: translate(1.5938rem, 0);
      }
      &:nth-of-type(2) {
        transform: translate(1.5938rem, -2rem);
      }
      &:nth-of-type(3) {
        transform: translate(-1.5938rem, -4rem);
      }
      &:nth-of-type(4) {
        transform: translate(1.5938rem, -6rem);
      }
      .btn-show-popup {
        width: 6.5rem;
        height: 6.5rem;
      }
    }
    .popup-item {
      &.popup-1 {
        top: 21.9688rem;
        left: 67.1667vw;
      }
      &.popup-3 {
        left: 67.1667vw;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    max-height: 23.5rem;
    .inner {
      max-height: 23.5rem;
    }
    &-main-text {
      margin-bottom: 1.5rem;
      font-size: 2.625rem;
    }
    &-ko-text {
      font-size: 0.875rem;
    }
  }
  .leaders {
    padding: 4rem 0;

    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    .sub-title {
      margin: 1.5rem 0 4.625rem;
      font-size: 1rem;
    }
    &-list {
      padding: 0 1.5rem;
      column-gap: 2rem;
    }
    &-item {
      width: calc((100% - 2rem) / 2);
      .img-box {
        min-width: 7.1875rem;
        width: 7.1875rem;
        height: 7.1875rem;
      }
    }
    .leaders-info {
      width: calc(100% - 115px);
      height: 100%;
    }
  }
  .people {
    padding: 0;
    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    .sub-title {
      margin: 1.5rem 0 2.5rem;
      font-size: 1rem;
    }
    .people-item {
      max-width: 53.3125rem;
      height: 19.125rem;
      background-size: auto 100%;
      transform: translate(-1.1875rem, 0);

      &:nth-of-type(2) {
        transform: translate(3.1875rem, -2rem);
      }
      &:nth-of-type(3) {
        background-position: left top;
        transform: translate(-1.1875rem, -4rem);
      }
      .content-box {
        padding: 0 3.5rem;

        .content-title {
          margin-bottom: 1.0625rem;
          font-size: 1.25rem;
        }
        .content-text {
          height: auto;
          margin-bottom: 2.7313rem;
          font-size: 0.75rem;
        }
        .btn-show-popup {
          width: 4rem;
          height: 4rem;
        }
      }
    }
    .popup-item {
      font-size: 1.5rem;
      &.popup-1 {
        top: 16.9688rem;
        left: 55%;
        transform: translateX(-50%);
      }
      &.popup-2 {
        top: 33.8438rem;
        left: 55%;
        transform: translateX(-50%);
      }
      &.popup-3 {
        top: 50.8125rem;
        left: 55%;
        transform: translateX(-50%);
      }
      &.popup-4 {
        top: 69.7813rem;
        left: 55%;
        transform: translateX(-50%);
      }
    }
  }
  .photo {
    padding: 0 0 4rem;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;

      br {
        display: none;
      }
    }
    .img-box {
      border-radius: 1rem;
    }
    .img-desc {
      margin-top: 1.5rem;
      font-size: 1rem;
    }
  }
  .bg-section {
    padding: 0 0 4rem;
  }
  .culture {
    padding: 4rem 9.1875rem;
    .title {
      font-size: 2.5rem;
    }
    .sub-title {
      margin: 1.5rem 0 2.5rem;
      font-size: 1rem;
    }
    &-item {
      width: 22.5rem;
      height: 20.5rem;
      border-radius: 1.5rem;
    }
    .item-title {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
    .item-desc {
      margin-bottom: 2rem;
      font-size: 1rem;
    }
    button {
      width: 9rem;
      height: 2.5rem;
      font-size: 1rem;
    }
  }
  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    background-size: cover;
    .inner {
      align-items: flex-start;
      padding: 0 1.5rem;

      br {
        display: none;
      }
    }

    &-main-text {
      font-size: 2rem;
      text-align: center;
    }

    &-ko-text {
      font-size: 1rem;
      text-align: left;
    }

    .btn-group {
      column-gap: 0.5rem;
    }
    button {
      width: 7.75rem;
      height: 2.75rem;
      font-size: 1rem;
    }
  }

  .leaders {
    padding: 3.25rem 0 0;
    .title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0.75rem;
    }
    .sub-title {
      font-size: 0.875rem;
    }
    &-list {
      flex-direction: column;
      padding: 0 1.5rem;
      row-gap: 3rem;
    }
    &-item {
      width: 100%;
      column-gap: 1rem;

      .img-box {
        min-width: 5rem;
        width: 5rem;
        height: 5rem;
      }
    }
    .leaders-info {
      width: 100%;
      flex-grow: 1;
    }
    .info-name {
      font-size: 1.5rem;
    }
    .info-rank {
      margin-left: 0.5rem;
      font-size: 1.25rem;
    }
    .career-box {
      font-size: 0.9375rem;
    }
  }

  .people {
    padding: 3rem 1.5rem 1rem;

    .title {
      font-size: 2rem;
      margin-bottom: 1.5rem;

      br {
        display: block;
      }
    }
    .sub-title {
      margin: 1rem 0 1.5rem;
      font-size: 0.9375rem;
      letter-spacing: -0.02em;
    }
    .people-item {
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      background-size: 100% 11.625rem;
      background-position: left top;
      transform: translate(0.75rem, 0);
      border-radius: 2rem;

      &:nth-of-type(odd) {
        background-position: left top;
        transform: translate(0.75rem, -0.75rem);
      }
      &:nth-of-type(3) {
        background-position: left top;
        transform: translate(0.75rem, -2rem);
      }
      &:nth-of-type(even) {
        background-position: right top;
        transform: translate(-0.75rem, -1.5rem);
      }

      .content-box {
        width: 100%;
        height: 100%;
        margin-top: 11.625rem;
        padding: 1.5rem 1rem;

        .content-title {
          display: inline-block;
          margin: 0 0 0.75rem 0;
          font-size: 1.25rem;
          white-space: nowrap;
        }
        .content-text {
          height: 4.8125rem;
          margin-bottom: 0;
          font-size: 0.875rem;
        }
        .btn-show-popup {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .popup-item {
      padding: 1.5rem 1rem;
      font-size: 0.875rem;

      &.popup-1 {
        top: 21.9688rem;
        left: 50%;
      }
      &.popup-2 {
        top: 45.2438rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
      &.popup-3 {
        top: 69.3125rem;
        left: 50%;
      }
      &.popup-4 {
        top: 93.2813rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  .photo {
    .title {
      margin-bottom: 1.5rem;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.34;

      br {
        display: block;
      }
    }
    .img-box {
      padding: 0 1.5rem;
      max-height: 10.0625rem;
      border-radius: 1rem;
    }
    .img-desc {
      margin-top: 0.8125rem;
      font-size: 0.9375rem;
    }
  }
  .culture {
    padding: 3rem 1.5rem 0;
    .title {
      margin-bottom: 1rem;
      font-size: 1.75rem;
      font-weight: 700;
    }
    .sub-title {
      font-size: 0.9375rem;
      margin-bottom: 1.5rem;
      word-break: keep-all;
      br {
        display: none;
      }
    }
    &-list {
      flex-direction: column;
      row-gap: 1rem;
    }
    &-item {
      width: 100%;
      padding: 4.125rem 2.125rem;
    }
    .item-title {
      margin-bottom: 0.75rem;
      font-size: 1.5rem;
    }
    .item-desc {
      margin-bottom: 1.5rem;
      font-size: 0.9375rem;
    }
    button {
      width: 7.75rem;
      height: 2.6875rem;
    }
  }
}
