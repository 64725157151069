.top-wrapper {
  width: 100%;
  height: 100%;
}

.user-header {
  width: 100vw;
  padding: 1rem 0;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border: solid 1px rgba(255, 255, 255, 0.24);
  background-image: linear-gradient(
    to bottom,
    rgba(141, 150, 171, 0.5),
    rgba(19, 24, 36, 0.5) 224%
  );

  .inner {
    max-width: 90rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .logo {
    flex-shrink: 0;
    width: 3.375rem;
    height: 1.5rem;

    button {
      display: block;
      width: auto;
      height: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  nav {
    margin-left: 13.125rem;
    margin-right: 3.5rem;
    ul {
      // column-gap: 4.1667vw;

      button {
        font-family: "Roboto";
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.3;
        text-transform: uppercase;
      }

      li {
        position: relative;

        .depth-menu {
          z-index: 20;
          position: absolute;
          top: 4.6875rem;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 0.5rem;
          padding: 2rem;
          background-image: linear-gradient(
            to bottom,
            rgba(141, 150, 171, 0.5),
            rgba(19, 24, 36, 0.5) 224%
          );
          border-radius: 0.75rem;
          backdrop-filter: blur(1.875rem);

          a {
            font-size: 1.5rem;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .menu {
    display: none;
  }

  .utils {
    position: relative;
    align-items: center;
    // height: 2.875rem;

    button {
      flex-shrink: 0;
      width: 3rem;
      height: 3rem;
    }

    .sub-menu {
      z-index: 20;
      position: absolute;
      top: 5.3875rem;
      left: -1.5rem;
      // left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
      padding: 2rem;
      background-image: linear-gradient(
        to bottom,
        rgba(141, 150, 171, 0.5),
        rgba(19, 24, 36, 0.5) 224%
      );
      border-radius: 0.75rem;
      -webkit-backdrop-filter: blur(1.875rem);
      backdrop-filter: blur(1.875rem);

      &.setting-menu {
        // left: 0;
        left: -3rem;
      }

      a,
      button {
        width: max-content;
        font-size: 1.35rem;
        color: #fff;
        font-weight: 500;
        font-family: "Roboto";
      }
    }
    .login-sub-menu {
      z-index: 20;
      position: absolute;
      top: 5.3875rem;
      left: 1rem;
      // left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
      padding: 2rem;
      background-image: linear-gradient(
        to bottom,
        rgba(141, 150, 171, 0.5),
        rgba(19, 24, 36, 0.5) 224%
      );
      border-radius: 0.75rem;
      -webkit-backdrop-filter: blur(1.875rem);
      backdrop-filter: blur(1.875rem);

      &.setting-menu {
        // left: 0;
        left: -3rem;
      }

      a,
      button {
        width: max-content;
        font-size: 1.35rem;
        color: #fff;
        font-weight: 500;
        font-family: "Roboto";
      }
    }
  }

  .mobile-menu-box {
    position: absolute;
    top: 3.875rem;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 2.375rem);
    padding: 1.5rem 1.5rem 4rem;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      background-color: rgb(178 183 219 / 25%);
      background-image: linear-gradient(
        45deg,
        rgba(158, 163, 200, 0.8),
        rgb(181 184 204 / 80%)
      );
    }

    nav {
      flex-grow: 1;

      li {
        &:hover {
          font-weight: 700;

          > button {
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-image: linear-gradient(
                to right,
                #fff -1%,
                rgba(255, 255, 255, 0) 101%
              );
            }
          }
        }

        button {
          position: relative;
          padding-right: 1.625rem;
          padding-bottom: 0.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .user-header {
    top: 0;
    padding: 1.125rem 0 1.0625rem;

    .inner {
      padding: 0 1.5rem;
    }

    .logo {
      width: 2.4375rem;
      height: 1.125rem;
    }

    nav {
      margin: 0 auto;
      ul {
        button {
          font-size: 1rem;
        }
      }
    }

    .utils {
      height: 1.25rem;

      .sub-menu {
        top: 3.6875rem;
        // padding: 1rem;

        button {
          height: auto;
          font-size: 1rem;
        }
      }
      .login-sub-menu {
        top: 3.6875rem;
        // padding: 1rem;

        button {
          height: auto;
          font-size: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .user-header {
    nav {
      display: none;
    }

    .menu {
      display: block;
    }

    .utils {
      display: none;
    }

    .mobile-menu-box {
      top: 3.65rem;
      left: -1px;
      width: 101%;

      nav,
      .utils {
        display: block;
        margin: 0;

        img {
          width: 1.75rem;
          height: 1.75rem;
        }

        li {
          button {
            font-size: 1.5rem;
          }
        }

        .depth-menu {
          top: 2.9875rem;
          padding: 0;
          background-image: none;
          backdrop-filter: unset;

          button {
            font-size: 1.25rem;
            font-weight: 400;

            & + button {
              &::after {
                display: none;
              }
            }
          }
        }

        button,
        a {
          font-size: 1.25rem;
          color: #fff;
          font-family: "Roboto";

          & + button {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              left: -1px;
              top: 50%;
              width: 2px;
              height: 1.25rem;
              transform: translateY(-50%);
              background-color: #fff;
            }
          }
        }

        a {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: -1px;
            top: 50%;
            width: 2px;
            height: 1.25rem;
            transform: translateY(-50%);
            background-color: #fff;
          }
        }
      }
    }
  }
}
