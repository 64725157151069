.login-form {
  .login-box {
    display: flex;
    border: 1px solid var(--color-gray);
    margin-bottom: 0.5rem;

    label {
      display: block;
      padding: 0.5rem 1rem;
      width: 7.1875rem;
      color: #fff;
      background-color: var(--color-gray);
    }
    input {
      width: calc(100% - 7.1875rem);
      padding: 0.5rem 1rem;

      &:focus {
        outline: none;
      }
    }
  }

  .btn-login {
    width: 100%;
    height: 48px;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: var(--color-gray);
  }
}

.helper-text {
  margin-top: 1rem;
  color: var(--color-red);
}
