.container {
  position: relative;
  z-index: 1;
  background: #f8f9fa;
  height: 3.75rem;
  padding: 0 2.5rem;
  box-shadow: 0 0 10px var(--color-border-gray);
}

.logo {
  display: flex;
  align-items: center;
  width: 15rem;
  height: 100%;
  flex-shrink: 0;
  background-color: #f8f9fa;
}

.img-logo {
  display: inline-block;
  height: 24px;
  vertical-align: middle;

  img {
    max-height: 100%;
  }
}

.alarm {
  width: 2.25rem;

  img {
    width: 1.25rem;
  }

  .badge {
    position: absolute;
    top: 0;
    left: 0.75rem;
    min-width: 1.625rem;
    height: 1.625rem;
    border-radius: 50%;
    border: 0.25rem solid #f8f9fa;
    background-color: #58b095;
    color: #f9f9f9;
    font-size: 0.625rem;
    line-height: 1.0625rem;
    text-align: center;
  }
}

.menu {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.875rem;
  margin-right: 2rem;

  img {
    min-width: 1.5rem;
  }
}

.running-program-box {
  column-gap: 0.75rem;
  width: calc(100% - 15rem);
}

.toolBar {
  width: 25.0625rem;
  padding-right: 2.5rem;
}

.user-select {
  cursor: pointer;

  .logout,
  .login {
    display: flex;
    column-gap: 0.5rem;
    padding: 0.4063rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.43;
    color: var(--color-gray);
    border-radius: 0.3125rem;
    border: solid 1px #e2e8f0;
    cursor: pointer;
  }
}
