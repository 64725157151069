// Roboto
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype"),
    url("../fonts/Roboto/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype"),
    url("../fonts/Roboto/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSansKR/NotoSansKR-Regular.ttf") format("truetype"),
    url("../fonts/NotoSansKR/NotoSansKR-Regular.woff") format("woff");
  font-display: swap;
}

// NotoSansKR
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSansKR/NotoSansKR-Regular.otf") format("opentype"),
    url("../fonts/NotoSansKR/NotoSansKR-Regular.ttf") format("truetype"),
    url("../fonts/NotoSansKR/NotoSansKR-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NotoSansKR/NotoSansKR-Medium.otf") format("opentype"),
    url("../fonts/NotoSansKR/NotoSansKR-Medium.ttf") format("truetype"),
    url("../fonts/NotoSansKR/NotoSansKR-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/NotoSansKR/NotoSansKR-Bold.otf") format("opentype"),
    url("../fonts/NotoSansKR/NotoSansKR-Bold.ttf") format("truetype"),
    url("../fonts/NotoSansKR/NotoSansKR-Bold.woff") format("woff");
  font-display: swap;
}

// NanumSquare
@font-face {
  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NanumSquare/NanumSquareAcR.ttf") format("truetype"),
    url("../fonts/NanumSquare/NanumSquareAcR.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NanumSquare/NanumSquareAcB.ttf") format("truetype"),
    url("../fonts/NanumSquare/NanumSquareAcB.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/NanumSquare/NanumSquareAcEB.ttf") format("truetype"),
    url("../fonts/NanumSquare/NanumSquareAcEB.woff") format("woff");
  font-display: swap;
}

// Pretendard
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Pretendard/Pretendard-Light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Pretendard/Pretendard-Regular.otf") format("opentype"),
    url("../fonts/Pretendard/Pretendard-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Pretendard/PretendardMedium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard/PretendardBold.woff") format("woff");
  font-display: swap;
}
