$delay-time: (
  0.05s,
  0.1s,
  0.15s,
  0.2s,
  0.25s,
  0.3s,
  0.35s,
  0.4s,
  0.45s,
  0.5s,
  0.55s
);

.intro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 43.75rem;
  row-gap: 0.5rem;
  background: url("../../assets/images/bg/recruit_intro.png") no-repeat top
    center / 100% auto;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 43.75rem;
  }

  &-text {
    flex-shrink: 0;
    white-space: nowrap;
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.25;
    white-space: pre-wrap;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          display: inline-block;
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }
  }
  &-main-text {
    margin-bottom: 2.125rem;
    font-size: 5rem;
    font-weight: 700;
    color: #5b54fe;
    text-transform: uppercase;
    font-family: "Pretendard";
  }
  &-ko-text {
    width: 100%;
    font-size: 1.5rem;
    color: #000;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: center;

    > div {
      display: inline-block;

      br {
        display: block;
      }
    }
  }

  .btn-group {
    column-gap: 1.5rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12.3125rem;
    height: 3.375rem;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    background-color: #5b54fe;
    border-radius: 999px;
    box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
    font-family: "Pretendard";
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.interview {
  position: relative;
  padding: 7.5rem 0;
  opacity: 0.73;
  background-image: linear-gradient(to top, #cad3e6 1%, #eef3ff 99%);
  background-position: top -13px center;

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 500;
    text-align: center;
  }

  &-list {
    display: flex;
    flex-direction: column;
    row-gap: 4.5rem;
  }

  &-item {
    max-width: 62.5rem;
    margin: 0 auto;
  }

  &-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .img-box {
      width: 14.125rem;
      height: 14.125rem;
      border-radius: 50%;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-content {
    margin: 3rem 0 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8d96ab;
    white-space: pre-wrap;
    text-align: center;
    font-family: "Pretendard";
  }

  .interviewee {
    font-size: 1.5rem;
    color: #000;
    font-family: "Pretendard";
    font-weight: 600;
  }
}

.recruit {
  color: #fff;

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.187;
    text-align: center;
  }

  .tag {
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;

    ul {
      display: flex;
      column-gap: 0.5rem;
    }

    &-box {
      padding: 0.5rem 1rem;
      color: #fff;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border: solid 1px rgba(255, 255, 255, 0.24);
      background: rgba(53, 57, 69, 0.7);
      border-radius: 0.5rem;
    }
  }

  .list-wrapper {
    max-width: 87.5rem;
    width: 100%;
    margin: 3.6875rem auto 0;

    .list-box {
      column-gap: 1rem;
      border-bottom: 1px solid #8d96ab;
    }
    .list-item {
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 13.75rem;
      height: 4.9375rem;
      font-size: 1.5rem;
      color: #5b54fe;
      text-align: center;
    }
    .list-container {
      li {
        column-gap: 1rem;
      }
      li:not(:last-child) {
        border-bottom: 1px solid #8d96ab;
      }
      .list-item {
        flex-shrink: 0;
        display: flex;
        color: #fff;
      }
    }
  }
}

.contact {
  padding-top: 10rem;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    line-height: 1.187;
    text-align: center;
  }

  .btn-recruit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31.25rem;
    height: 3.8125rem;
    margin: 0 auto;
    font-size: 1.5rem;
    color: #fff;
    font-family: "Pretendard";
    border-radius: 999px;
    -webkit-backdrop-filter: blur(0.625rem);
    backdrop-filter: blur(0.625rem);
    border: solid 1px #fff;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      to right,
      rgb(230 233 233 / 25%) 101%,
      rgb(255 255 255 / 15%) 62%
    );
  }
}

.process {
  padding-top: 10rem;

  .sub-title {
    display: block;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }

  .title {
    margin-top: 1rem;
    margin-bottom: 6rem;
    font-size: 4rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 6rem;

    li {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 19.75rem;
      height: 19.75rem;
      font-size: 1.5rem;
      color: #fff;
      line-height: 1.5;
      border: 2px solid #5b54fe;
      border-radius: 50%;
      font-family: "Pretendard";
      white-space: pre-wrap;
      text-align: center;

      &:not(:first-child) {
        position: relative;
        margin-left: -3rem;

        &::before {
          content: "";
          top: 50%;
          left: 0.95rem;
          transform: translateY(-50%);
          position: absolute;
          width: 2rem;
          border-radius: 50%;
          height: 59%;
          -webkit-backdrop-filter: blur(0.1875rem);
          backdrop-filter: blur(0.1875rem);
        }
      }
    }
  }

  .helper-text {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    font-family: "Pretendard";
  }

  .email {
    display: block;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
}

.faq {
  padding-top: 10rem;
  max-width: 87.5rem;
  width: 100%;
  margin: 0 auto;
  color: #fff;

  .title {
    margin-bottom: 6rem;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.3194;
    text-align: center;
  }

  &-box {
    padding: 0 3rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.24);
  }

  &-list {
    padding: 3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    &:hover {
      cursor: pointer;
    }

    .faq-img {
      display: block;
      width: 5rem;
      height: 4rem;
      margin-left: auto;
    }
  }

  .question-item {
    column-gap: 2.5625rem;
    color: #c2bfff;
    font-weight: 500;

    &.active {
      color: #5f5aff;

      .text {
        font-size: 1.75rem;
      }
    }

    .num {
      font-size: 3rem;
      font-weight: 500;
      line-height: 1.31;
    }
    .text {
      font-size: 1.5rem;
      font-family: "Pretendard";
    }
  }

  .answer-list {
    display: none;
    margin-top: 2.5625rem;

    &.active {
      display: block;
    }
  }

  .answer-item {
    display: flex;
    align-items: flex-start;
    column-gap: 2.5rem;
    color: #fff;
    font-weight: 500;

    .content-box {
      width: calc(100% - 3.375rem);
    }

    .num {
      font-size: 3rem;
    }
    .text {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.3541;
      font-family: "Pretendard";
      word-break: keep-all;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
      max-height: 31.25rem;
      margin-top: 1rem;
      object-fit: contain;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  max-width: max-content;
  width: 100%;
  margin: 3rem auto 0;
  padding: 2.0625rem 3.1875rem;
  column-gap: 2.0625rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: solid 2px rgba(255, 255, 255, 0.55);
  border-radius: 999px;
  background-image: linear-gradient(97deg, #606369 1%, #404653 98%);

  &-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.875rem;
    overflow: hidden;

    button {
      font-size: 1.25rem;
      color: #fff;

      &:hover {
        font-weight: 700;
      }
    }
  }
}

// common
.gradient-bg-section {
  background-image: linear-gradient(to top, #ededed, #fff);
}

.bg-area {
  padding: 10rem 0;
}

.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  width: 4rem;
  height: 2rem;
  margin: 0;
  color: #fff;
  font-weight: 700;
  border-radius: 0.25rem;
  background-color: red;
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .interview {
    .title {
      font-size: 60px;
      line-height: 79px;
    }
  }
  .recruit {
    .title {
      font-size: 60px;
      line-height: 79px;
    }
  }
  .contact {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .faq {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    max-height: 23.5rem;
    .inner {
      max-height: 23.5rem;
    }
    &-main-text {
      margin-bottom: 1.5rem;
      font-size: 2rem;
    }
    &-ko-text {
      font-size: 1rem;
    }
  }
  .interview {
    padding: 4rem 0;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
    }
    &-list {
      padding: 0 1.5rem;
      row-gap: 4rem;
    }
    &-content {
      margin-top: 3rem;
      font-size: 1rem;
    }
    .interviewee {
      font-size: 1rem;
    }
  }
  .bg-area {
    padding: 4rem 0;
  }
  .recruit {
    padding: 0 1.5rem;
    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    .list-wrapper {
      margin-top: 1.5rem;
      padding-bottom: 0.5rem;
      overflow-x: auto;
      &::-webkit-scrollbar {
        border-radius: 999px;
        height: 2px;
        background-color: #707070;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5b54fe;
        border-radius: 999px;
      }
      &::-webkit-scrollbar-track {
      }
      .list-box {
        width: 65.625rem;
        column-gap: 0.5rem;
      }
      .list-item {
        width: 9.75rem;
        height: 4rem;
        font-size: 1.25rem;
      }
      .list-container {
        li {
          column-gap: 0.5rem;
        }
      }
    }
  }
  .contact {
    padding-top: 4rem;
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
    }
    .btn-recruit {
      width: 31.25rem;
      height: 3.8125rem;
      font-size: 1.5rem;
    }
  }
  .process {
    padding-top: 4rem;
    .sub-title {
      font-size: 1rem;
    }
    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
    }
    ul {
      margin-bottom: 4rem;
      li {
        width: 13.75rem;
        height: 13.75rem;
        font-size: 1rem;
      }
    }
    .helper-text {
      margin-bottom: 1.5rem;
    }
  }
  .faq {
    padding-top: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .title {
      margin-bottom: 2.5rem;
      font-size: 3rem;
    }

    &-box {
      padding: 0 2.125rem;
    }

    &-list {
      padding: 2.125rem 0 1.5rem;

      .faq-img {
        width: 3.4375rem;
        height: 2.875rem;
      }
    }

    .question-item {
      &.active {
        .text {
          font-size: 1.125rem;
        }
      }

      .num {
        font-size: 2rem;
      }
      .text {
        font-size: 0.875rem;
      }
    }

    .answer-list {
      .num {
        font-size: 2rem;
      }
      .text {
        font-size: 0.875rem;
      }
    }
  }
  .pagination {
    margin-top: 2rem;
    padding: 1.5rem 3.625rem;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    background-size: cover;
    .inner {
      align-items: flex-start;
      padding: 0 1.5rem;
    }
    &-main-text {
      font-size: 2rem;
      text-align: center;
    }
    &-ko-text {
      font-size: 1rem;
      text-align: left;
    }
    .btn-group {
      column-gap: 0.5rem;
    }
    button {
      width: 7.75rem;
      height: 2.75rem;
      font-size: 1rem;
    }
  }
  .interview {
    padding: 3.25rem 0 3rem;

    .title {
      width: 100%;
      padding: 0 0.25rem;
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1.125;
      word-break: keep-all;
    }
    &-box {
      .img-box {
        width: 6rem;
        height: 6rem;
      }
    }
    &-content {
      margin-top: 1.5rem;
      font-size: 0.9375rem;
      word-break: keep-all;
      br {
        display: none;
      }
    }
  }
  .recruit {
    padding: 0 1.5rem;

    .title {
      font-size: 1.75rem;
      margin-bottom: 1.75rem;
    }
  }
  .contact {
    .title {
      width: 100%;
      margin-bottom: 1.75rem;
      padding: 0 0.25rem;
      font-size: 1.75rem;
      line-height: 1.125;
      word-break: keep-all;
    }
    .btn-recruit {
      width: 13.5625rem;
      height: 2.6875rem;
      font-size: 1rem;
    }
  }
  .process {
    .title {
      font-size: 1.75rem;
      font-weight: 700;
    }
    ul {
      flex-direction: column;
      align-items: center;
      li {
        width: 9.5rem;
        height: 9.5rem;
        font-size: 0.875rem;
        &:not(:first-child) {
          margin-left: 0;
          margin-top: -1.5rem;
          &::before {
            top: 0;
            left: 49.5%;
            transform: translateX(-50%);
            width: 4.5rem;
            height: 2rem;
          }
        }
      }
    }
    .helper-text {
      padding: 0 1.5rem;
      font-size: 0.875rem;
    }
    .email {
      padding: 0 1.5rem;
      font-size: 0.875rem;
    }
  }
  .faq {
    padding: 3rem 1.5rem 0;

    .title {
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
    }

    &-box {
      padding: 0 0.5rem 0 0.75rem;
      border-radius: 1rem;
    }

    &-list {
      padding: 1.5rem 0;

      .faq-img {
        width: 2.5rem;
        height: 2rem;
      }
    }

    .question-item {
      column-gap: 0.375rem;

      .num {
        font-size: 0.9375rem;
      }
      .text {
        font-size: 0.9375rem;
      }
    }

    .answer-list {
      margin-top: 1.5rem;
    }

    .answer-item {
      align-items: baseline;
      column-gap: 0.375rem;

      .num {
        font-size: 0.9375rem;
      }
      .text {
        font-size: 0.9375rem;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
