.btn-go-top {
  z-index: 10;
  position: fixed;
  bottom: 1.75rem;
  right: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  height: 4.375rem;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #393c47;

  img {
    width: auto;
    height: 33%;
    transform: rotate(-90deg);
  }

  @media screen and (max-width: 768px) {
    right: 1rem;
    bottom: 1rem;
    width: 3.375rem;
    height: 3.375rem;
  }
}
