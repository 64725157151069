.footer {
  padding: 5rem 0;
  color: #fff;
  background-color: #393c47;

  .menu {
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    // padding: 0 13.5417vw;
  }

  .menu-list {
    column-gap: 5.375rem;

    .menu-title {
      margin-bottom: 1rem;
      font-weight: 700;
      font-family: "Pretendard";
      line-height: 1.1875;
      white-space: nowrap;
    }
  }

  .menu-item {
    li {
      margin-bottom: 0.5rem;
      font-weight: 400;
      line-height: 1.5;
      cursor: pointer;
      font-family: "Pretendard";
      white-space: nowrap;
    }
  }

  .btn-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.125rem;
    height: 2.5rem;
    font-size: 1.125rem;
    color: #fff;
    background-color: #1d212e;
    border-radius: 999px;
    font-family: "Pretendard";
  }

  .social-group {
    margin-left: 3.6979vw;
    column-gap: 3.2083vw;

    li {
      font-family: "Pretendard";

      img {
        cursor: pointer;
      }
    }
  }

  .info {
    max-width: 75rem;
    margin: 9.1875rem auto 0;
    // padding: 0 13.5417vw;
    line-height: 1.5;

    p {
      font-family: "Pretendard";
      font-weight: 300;
    }

    &.en-info {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1536px) {
  .footer {
    .menu {
      // padding: 0 4.25rem;
    }

    .info {
      // padding: 0 4.25rem;
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer {
    .menu {
      flex-wrap: wrap;
      row-gap: 2rem;
      column-gap: 10rem;
      padding: 0 4.25rem;
    }

    .social-group {
      margin-left: 0;
    }

    .info {
      padding: 0 4.25rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    .menu {
      padding: 0 1.5rem;
    }

    .info {
      padding: 0 1.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 1.5rem;

    .menu {
      padding: 0;
      row-gap: 0;
    }
    .social-group {
      margin-top: 3rem;
    }
    .info {
      width: 18.75rem;
      margin: 6.625rem 0 0;
      margin-right: 5.2083vw;
      padding: 0;
    }
    .btn-download {
      font-size: 1rem;
    }
    .en-info {
      font-size: 0.875rem;
    }
  }
}
