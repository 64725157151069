.page-btns {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-border-gray);
  border-radius: 50%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0.5;

    &.btn-prev img {
      transform: rotate(-180deg);
    }

    img {
      display: block;
      width: auto;
      height: 50%;
    }
  }

  &.active {
    border: 1px solid var(--color-font-gray);

    button {
      opacity: 1;
    }
  }
}
