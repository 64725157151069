.popup-wrapper {
  padding-bottom: 32px;
  border-radius: 3px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 1);
}
.popup-image {
  height: 100%;
  overflow: hidden;
}

.bottom-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(161 161 170);
  overflow: hidden;
}
.btn-close {
  padding: 0.5rem;
  flex-shrink: 0;
  filter: invert(70%);
  cursor: pointer;

  img {
    display: block;
    width: 1rem;
    height: 1rem;
  }
}
