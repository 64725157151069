.content-box {
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(3.125rem);
  backdrop-filter: blur(3.125rem);
  border: solid 1px var(--color-border-gray);
}

.box-title {
  color: #707070;
  font-size: 1rem;
}
.list-title {
  color: #707070;
  font-size: 0.875rem;
}

.items-center {
  display: flex;
  justify-content: space-between;
}

.list-wrapper {
  .list-header {
    padding: 0 1rem;
    background-color: var(--color-bg-light-gray);
    border-radius: 0.25rem;
    color: var(--color-gray);

    .list-box {
      // ul
      display: flex;
      justify-content: space-between;
      column-gap: 15px;

      .title-list-item {
        // li

        .header-text {
          width: 100%;
          padding: 1rem 0;
          text-align: center;
        }

        // 번호
        &:nth-child(1) {
          .header-text {
            width: 70px;
          }
        }

        // 제목
        &:nth-child(2) {
          flex-grow: 1;
          .header-text {
            // width: 320px;
          }
        }

        // 상태
        &:nth-child(3) {
          .header-text {
            width: 70px;
          }
        }

        // 작성자
        &:nth-child(4) {
          .header-text {
            width: 120px;
          }
        }

        // 등록일
        &:nth-child(5) {
          .header-text {
            width: 120px;
          }
        }

        // 수정자
        &:nth-child(6) {
          .header-text {
            width: 120px;
          }
        }

        // 수정일
        &:nth-child(7) {
          .header-text {
            width: 120px;
          }
        }
      }
    }
  }

  .list-container {
    .list-box {
      // ul
      max-height: 520px;
      overflow: hidden;
      overflow-y: auto;
      margin: 0.5rem 0 1rem;

      .content-list-item {
        // li
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 15px;
        height: 50px;
        padding: 0 1rem;
        border-bottom: 1px solid var(--color-border-gray);
        cursor: pointer;

        .list-item {
          // padding: 0.5rem 0;
          text-align: center;

          > div {
            width: 100%;
          }

          // 번호
          &.number {
            flex-shrink: 0;
            width: 70px;
          }

          // 제목
          &.name {
            flex-shrink: 0;
            // width: 320px;
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            padding-left: 2rem;
          }
          //썸네일
          &.thumbnail {
            display: flex;
            flex-grow: 1;
            width: 90px;

            // width: 550px;

            justify-content: center;
            align-items: center;
            .thumbnailbox {
              display: flex;
              max-width: 350px;
              justify-content: space-between;
              align-items: center;
            }
          }
          // 상태
          &.status {
            flex-shrink: 0;
            width: 70px;
          }

          // 작성자, 수정자
          &.email {
            flex-shrink: 0;
            width: 120px;
          }

          // 등록일, 수정일
          &.date {
            flex-shrink: 0;
            width: 120px;
          }
        }
      }
      .url {
        background-color: var(--color-green);
        width: 90px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 20px;
        transition: 0.5s;
        &:hover {
          opacity: 75%;
        }
      }
    }
  }
}

.social-content-box {
  .list-wrapper {
    .list-box {
      .title-list-item {
        // li
        // 번호
        &:nth-child(1) {
          .header-text {
            width: 70px;
          }
        }

        // 제목
        &:nth-child(2) {
          flex-grow: 0;
          width: 300px;
          .header-text {
            width: 300px;
            // width: 320px;
          }
        }
        // 썸네일
        &:nth-child(3) {
          // flex-grow: 1;
          width: 200px;
          .header-text {
            width: 200px;
          }
        }
        // 링크가기
        &:nth-child(4) {
          width: 120px;

          .header-text {
            width: 120px;
          }
        }

        // 사용여부
        &:nth-child(5) {
          width: 120px;
          .header-text {
            width: 120px;
          }
        }

        // 등록자
        &:nth-child(6) {
          width: 120px;
          .header-text {
            width: 120px;
          }
        }

        // 등록일시
        &:nth-child(7) {
          width: 120px;
          .header-text {
            width: 120px;
          }
        }
      }
    }

    .list-container {
      .list-box {
        // ul
        margin: 0.5rem 0 1rem;

        .content-list-item {
          // li
          display: flex;
          justify-content: space-between;
          height: 90px;
          column-gap: 15px;
          padding: 0 1rem;
          border-bottom: 1px solid var(--color-border-gray);
          cursor: pointer;

          .list-item {
            padding: 1rem 0;
            text-align: center;

            > div {
              flex-shrink: 0;
              width: 100%;
            }

            // 번호
            &.number {
              flex-shrink: 0;
              width: 70px;
            }

            // 제목
            &.name {
              flex-shrink: 0;
              width: 300px;
              flex-grow: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            // 썸네일
            &.thumbnailbox {
              width: 200px;
            }
            // 링크가기
            &.url {
              width: 120px;
            }

            // 사용여부
            &.use_yn {
              flex-shrink: 0;
              width: 120px;
            }
            // 등록일, 수정일
            &.date {
              flex-shrink: 0;
              width: 120px;
            }
          }
        }
        .url {
          background-color: var(--color-green);
          width: 20px;
          height: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 20px;
          transition: 0.5s;
          &:hover {
            opacity: 75%;
          }
        }
      }
    }
  }
}
