.contact-us {
  margin-top: -1px;
  padding: 12.4375rem 0 0;
  background-repeat: no-repeat;
  background-position: left center, right center;

  &.pd-0 {
    padding: 0;
  }

  .title {
    margin-bottom: 3.125rem;
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 600;
    color: #fff;
    text-align: center;
    font-family: "Pretendard";
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.3125rem;
    width: 16.1rem;
    height: 4.375rem;
    margin: 0 auto;
    font-size: 1.5rem;
    color: #fff;
    line-height: 1.21;
    border-radius: 7.8125rem;
    -webkit-backdrop-filter: blur(0.625rem);
    backdrop-filter: blur(0.625rem);
    border: solid 1px rgba(255, 255, 255, 0.2);
    // background-image: linear-gradient(to right, #e6e9e9 0%, #fff 62%);
    transition: opacity 0.2s ease-out;
    font-family: "Pretendard";

    img {
      width: 1.875rem;
      height: 1.25rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

// common
.contact-us.is-admin .title {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -2rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: red;
    cursor: pointer;
  }
}
.contact-us.is-admin .intro {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 12rem;
    right: 3rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: red;
    cursor: pointer;
  }
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .contact-us {
    padding: 160px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .contact-us {
    padding: 4rem 0;

    .title {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
    }

    button {
      width: 15rem;
      height: 4rem;
      column-gap: 0.7188rem;
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-us {
    margin-top: -1px;
    padding: 3rem 1.5rem;

    .title {
      width: 100%;
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
      word-break: keep-all;
      line-height: 1.3;
    }

    button {
      width: 9.8125rem;
      height: 2.6875rem;
      column-gap: 0.8125rem;
      font-size: 1rem;
      background-size: 100% 100%;

      img {
        height: 33%;
      }
    }
  }
}
