/*본인이 설정하고 싶은 폰트 import하기*/
@import "./fonts.scss";

/* 폰트 설정 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  content: "Roboto";
  font-family: "Roboto";
}
.ql-font-Roboto {
  font-family: "Roboto";
}

/* 폰트 설정 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="NotoSansKR"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="NotoSansKR"]::before {
  content: "NotoSansKR";
  font-family: "NotoSansKR";
}
.ql-font-NotoSansKR {
  font-family: "NotoSansKR";
}

/* 폰트 설정 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="NanumSquare"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="NanumSquare"]::before {
  content: "NanumSquare";
  font-family: "NanumSquare";
}
.ql-font-NanumSquare {
  font-family: "NanumSquare";
}

/* 폰트 설정 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Pretendard"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Pretendard"]::before {
  content: "Pretendard";
  font-family: "Pretendard";
}
.ql-font-NanumSquare {
  font-family: "Pretendard";
}

/* 정렬 설정 */
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}

/* 폰트 크기 */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="38px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="38px"]::before {
  content: "38px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="42px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="42px"]::before {
  content: "42px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="44px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="44px"]::before {
  content: "44px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="46px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="46px"]::before {
  content: "46px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="50px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="50px"]::before {
  content: "50px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="52px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="52px"]::before {
  content: "52px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="64px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="64px"]::before {
  content: "64px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="80px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="80px"]::before {
  content: "80px";
}

.ql-size-10px {
  font-size: 0.625rem;
}
.ql-size-12px {
  font-size: 0.75rem;
}
.ql-size-14px {
  font-size: 0.875rem;
}
.ql-size-16px {
  font-size: 1rem;
}
.ql-size-18px {
  font-size: 1.125rem;
}
.ql-size-20px {
  font-size: 1.25rem;
}
.ql-size-22px {
  font-size: 1.375rem;
}
.ql-size-24px {
  font-size: 1.5rem;
}
.ql-size-28px {
  font-size: 1.75rem;
}
.ql-size-30px {
  font-size: 1.875rem;
}
.ql-size-32px {
  font-size: 2rem;
}
.ql-size-36px {
  font-size: 2.25rem;
}
.ql-size-38px {
  font-size: 2.375rem;
}
.ql-size-40px {
  font-size: 2.5rem;
}
.ql-size-42px {
  font-size: 2.625rem;
}
.ql-size-44px {
  font-size: 2.75rem;
}
.ql-size-46px {
  font-size: 2.875rem;
}
.ql-size-48px {
  font-size: 3rem;
}
.ql-size-50px {
  font-size: 3.125rem;
}
.ql-size-52px {
  font-size: 3.25rem;
}
.ql-size-64px {
  font-size: 4rem;
}
.ql-size-80px {
  font-size: 5rem;
}

@media screen and (max-width: 1024px) {
  .ql-size-10px {
    font-size: 0.34375rem;
  }
  .ql-size-12px {
    font-size: 0.4125rem;
  }
  .ql-size-14px {
    font-size: 0.48125rem;
  }
  .ql-size-16px {
    font-size: 0.55rem;
  }
  .ql-size-18px {
    font-size: 0.61875rem;
  }
  .ql-size-20px {
    font-size: 0.6875rem;
  }
  .ql-size-22px {
    font-size: 0.75625rem;
  }
  .ql-size-24px {
    font-size: 0.825rem;
  }
  .ql-size-28px {
    font-size: 0.9625rem;
  }
  .ql-size-30px {
    font-size: 1.03125rem;
  }
  .ql-size-32px {
    font-size: 1.1rem;
  }
  .ql-size-36px {
    font-size: 1.2375rem;
  }
  .ql-size-38px {
    font-size: 1.30625rem;
  }
  .ql-size-40px {
    font-size: 1.375rem;
  }
  .ql-size-42px {
    font-size: 1.44375rem;
  }
  .ql-size-44px {
    font-size: 1.5125rem;
  }
  .ql-size-46px {
    font-size: 1.58125rem;
  }
  .ql-size-48px {
    font-size: 1.65rem;
  }
  .ql-size-50px {
    font-size: 1.71875rem;
  }
  .ql-size-52px {
    font-size: 1.7875rem;
  }
  .ql-size-64px {
    font-size: 2.2rem;
  }
  .ql-size-80px {
    font-size: 2.75rem;
  }
}
@media screen and (max-width: 768px) {
  .ql-size-10px {
    font-size: 0.75rem;
  }
  .ql-size-12px {
    font-size: 0.75rem;
  }
  .ql-size-14px {
    font-size: 0.75rem;
  }
  .ql-size-16px {
    font-size: 0.75rem;
  }
  .ql-size-18px {
    font-size: 0.75rem;
  }
  .ql-size-20px {
    font-size: 0.75rem;
  }
  .ql-size-22px {
    font-size: 0.75rem;
  }
  .ql-size-24px {
    font-size: 0.75rem;
  }
  //////// font-size: 12px 고정 ////////
  .ql-size-28px {
    font-size: 0.8rem;
  }
  .ql-size-30px {
    font-size: 0.8rem;
  }
  .ql-size-32px {
    font-size: 0.8rem;
  }
  //////// font-size: 12.8px 고정 ////////
  .ql-size-36px {
    font-size: 0.9rem;
  }
  .ql-size-38px {
    font-size: 0.95rem;
  }
  .ql-size-40px {
    font-size: 1rem;
  }
  .ql-size-42px {
    font-size: 1.05rem;
  }
  .ql-size-44px {
    font-size: 1.1rem;
  }
  .ql-size-46px {
    font-size: 1.15rem;
  }
  .ql-size-48px {
    font-size: 1.2rem;
  }
  .ql-size-50px {
    font-size: 1.25rem;
  }
  .ql-size-52px {
    font-size: 1.3rem;
  }
  .ql-size-64px {
    font-size: 1.6rem;
  }
  .ql-size-80px {
    font-size: 2rem;
  }
}
