$delay-time: (0.05s, 0.1s, 0.15s, 0.2s, 0.25s, 0.3s, 0.35s, 0.4s, 0.45s);

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  row-gap: 0.5rem;
  background: url("../../assets/images/bg/nextlook.png") no-repeat;
  background-size: cover;
  background-position: top 35% center;

  .inner {
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
    // padding: 0 13.5417vw;
    // padding-top: 16.6875rem;
  }

  &-text {
    white-space: nowrap;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
    font-size: 2rem;
    line-height: 1.25;
    text-align: center;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }
  }
  &-ko-text {
    margin-top: 1.5rem;
    font-size: 5rem;
    line-height: 1.2;
    font-family: "Pretendard";
    font-weight: 400;npm start

    > div {
      p {
        span {
          display: block;
        }
        &:nth-child(2) {
          font-weight: 600;
        }
        &:last-child {
          font-size: 5rem;
          margin-top: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }

  .btn-group {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
    column-gap: 1.5625rem;
    row-gap: 0.5rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 12.3125rem;
    padding: 0 3.57rem;
    height: 4.6875rem;
    font-size: 1.5rem;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    background-color: #5b54fe;
    border-radius: 999px;
    box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
    font-family: "Pretendard";
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.service {
  position: relative;
  padding: 7.5rem 0 0;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3125;
    text-align: center;
  }

  .sub-title {
    display: block;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-family: "Pretendard";
  }

  &-box {
    position: relative;
    display: flex;
    gap: 0.5rem;
    max-width: 87.5rem;
    height: 24.625rem;
    margin: 3.75rem auto 0;

    video {
      width: 100%;
      background-color: #000;
    }

  }
}

#nextlook-advantage {
  width: 100%;
  height: 100%;
}

.advantage {
  position: relative;
  width: 100%;
  // padding-top: 43.17%;
  // height: 54.6875rem;
  padding: 27.3438rem 0;

  .text-box {
    z-index: 1;
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg/nextlook_ad.png") no-repeat top
      center / cover;
    opacity: 0.5;
  }

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1875;
    text-align: center;
    font-family: "Pretendard";
  }

  .advantage-list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;
  }

  .advantage-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 28rem;
    border-radius: 3rem;
    overflow: hidden;
    background-color: #fff;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .img-box {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -1px;
          width: 35.7%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
    }

    .img-box {
      position: relative;
      width: 51.21%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        width: 35.7%;
        height: 100%;
        background-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-box {
      width: 48.79%;
      padding: 0 4.375rem;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .num {
        display: block;
        font-size: 3.25rem;
        line-height: 1.1923;
        color: #8d96ab;
        font-family: "Pretendard";
      }
      .content-title {
        display: block;
        margin: 0.75rem 0 3rem;
        font-size: 3rem;
        line-height: 1.208;
        font-weight: 600;
        color: #5f5aff;
        font-family: "Pretendard";
      }
      .content-text {
        font-size: 1.5rem;
        line-height: 1.5;
        font-family: "Pretendard";
      }
    }
  }

  &-box {
    position: relative;

    max-width: 87.5rem;
    margin: 3.75rem auto 0;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem;
      max-width: 58rem;
      width: 100%;
      margin: 0 auto;
    }

    li {
      width: 100%;
      padding: 2.5rem 5.375rem 2.5rem;
      text-align: center;
      font-size: 1.875rem;
      color: #0a1434;
      font-weight: 700;
      line-height: 1.208;
      border-radius: 999px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      font-family: "Pretendard";
    }

    video {
      width: 100%;
      background-color: #000;
    }

    .btn-group {
      margin-top: 2rem;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18.5625rem;
        height: 4.6875rem;
        margin: 0 auto;
        font-size: 1.5rem;
        color: #fff;
        font-weight: 600;
        background-color: #5b54fe;
        border-radius: 999px;
        box-shadow: 0 1.5rem 2rem 0 rgba(24, 45, 112, 0.36);
        font-family: "Pretendard";
      }
    }
  }
}

.gradient-bg-section {
  // background-image: linear-gradient(to top, #cad3e6 0%, #eef3ff 100%);
}

.bg-area {
  padding: 10rem 0;
}

// common
.gradient-bg-section {
  background-color: #e1e7f6;
  background-image: linear-gradient(to top, #cad3e6 0%, #eef3ff 100%);
}

.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
  font-family: "Pretendard";
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// response
@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .intro {
    background-size: cover;
  }
  .service {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .advantage {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
    .text-box {
      top: 15%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .advantage {
    .advantage-list {
      padding: 0 1.5rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    &-en-text {
      font-size: 2.5rem;
    }
    &-ko-text {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
    .btn-group {
      margin-top: 2.4375rem;
      column-gap: 0.8125rem;
    }
    button {
      // width: 6.5625rem;
      height: 2.5rem;
      font-size: 0.75rem;
    }
  }

  .service {
    padding: 3.9375rem 0 0;

    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }

    .sub-title {
      font-size: 1rem;
    }

    &-box {
      flex-wrap: wrap;
      max-width: 38.625rem;
      height: 41.125rem;
      
      iframe {
        height: 50%;
      }
    }
  }

  .gradient-bg-section {
    // padding: 0 0 2.8125rem;

    .title {
      font-size: 2.5rem;
    }

    .advantage {
      margin: 2.75rem auto 0;
      padding: 12.3438rem 0;
      
      .title {
        margin-bottom: 2.5rem;
      }

      .advantage-item {
        height: 19.5625rem;

        .img-box {
          width: 49.21%;
        }

        .content-box {
          width: 50.79%;
          padding: 0 3.0625rem;
          .num {
            font-size: 2.25rem;
          }
          .content-title {
            font-size: 2.0625rem;
          }
          .content-text {
            font-size: 1.5rem;
            letter-spacing: -0.06em;
          }
        }
      }

      &-box {
        padding: 0 1.5rem;
        ul {
          row-gap: 0.625rem;
        }
        li {
          padding: 0.6875rem 5.3125rem;
          font-size: 1rem;
        }
      }
    }
  }

  .bg-area {
    padding: 4rem 0;
  }

  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      padding: 0 1.5rem;
    }

    &-ko-text {
      width: 100%;
      margin-top: 0.75rem;
      font-size: 1rem;

      > div {
        span {
          word-break: keep-all;
          white-space: initial;
        }
      }
    }

    .btn-group {
      column-gap: 0.5rem;
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: center;
    }

    button {
      // width: 7.75rem;
      height: 2.75rem;
      font-size: 1rem;
    }
  }

  .service {
    padding: 3.25rem 1.5rem 0;
    padding-top: 0;

    .title {
      width: 100%;
      font-size: 1.75rem;
      font-weight: 700;
      word-break: keep-all;
    }

    .sub-title {
      word-break: keep-all;
      font-size: 0.875rem;
    }

    &-box {
      height: auto;
      margin-top: 3rem;
      padding: 0 1.5rem;

      li {
        padding: 1rem 2.4375rem;
        font-size: 0.875rem;
        line-height: 1.14;
        word-break: keep-all;
      }

      .btn-group {
        button {
          width: 11.75rem;
          height: 2.75rem;
          font-size: 1rem;
        }
      }
    }
  }

  .gradient-bg-section {
    padding-top: 3.25rem;
    background-color: #f3f3f3;

    .application {
      .title {
        margin-bottom: 7rem;
        font-size: 1.75rem;
      }

      &-box {
        margin-top: 4.625rem;

        ul {
          column-gap: 0.5rem;
          row-gap: 4.125rem;
        }

        li {
          width: calc((100% - 0.5rem) / 2);
          height: 13.125rem;
          padding: 4.625rem 0.5rem 0;
          border-radius: 8.9375rem 0 0 0;
          -webkit-backdrop-filter: none;
          backdrop-filter: none;
          box-shadow: 1px 0 20px 0 rgba(91, 84, 254, 0.1);
          background-color: #fff;

          .img-box {
            top: -3.375rem;
            width: 6.25rem;
            height: 6.25rem;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          strong {
            margin-bottom: 0.75rem;
            font-size: 0.75rem;
          }

          p {
            font-size: 0.9375rem;
          }
        }

        .desc-text {
          margin-top: 0.75rem;
          padding: 0 1.5rem;
          // display: none;
        }
      }
    }

    .advantage {
      // padding-top: 52.5%;
      .title {
        font-size: 1.75rem;
      }

      .advantage-list {
        display: flex;
        flex-direction: column;
        row-gap: 2.5rem;
        max-width: 87.5rem;
        width: 100%;
        margin: 0 auto;
      }

      .advantage-item {
        flex-direction: column;
        height: 100%;
        border-radius: 1rem;

        &:nth-child(even) {
          flex-direction: column;
        }

        .img-box {
          width: 100%;

          &::before {
            display: none;
          }

          img {
            height: 10rem;
          }
        }

        .content-box {
          width: 100%;
          padding: 1.5rem 1rem;

          .num {
            display: inline-block;
            font-size: 0.9375rem;
          }
          .content-title {
            display: inline-block;
            margin: 0 0 0.75rem 0.5rem;
            font-size: 0.9375rem;
            white-space: nowrap;
          }
          .content-text {
            font-size: 0.9375rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .gradient-bg-section {
    .advantage {
      // padding-top: 100%;
      &-box {
        margin: 2.75rem auto 0;
      }
    }
  }
}
