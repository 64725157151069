$delay-time: (0.05s, 0.1s, 0.15s, 0.2s, 0.25s, 0.3s, 0.35s, 0.4s, 0.45s);

.intro {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 67.5rem;
  height: 100vh;
  row-gap: 0.5rem;
  background: url("../../assets/images/bg/tivv_intro.png") no-repeat top center /
    cover;

  .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    padding-bottom: 7.125rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 12.3125rem;
      white-space: nowrap;
      padding: 0 3.57rem;
      height: 4.6875rem;
      font-size: 1.5rem;
      color: #fff;
      font-weight: 600;
      background-color: #5b54fe;
      border-radius: 999px;
      box-shadow: 0 24px 32px 0 rgba(24, 45, 112, 0.36);
      font-family: "Pretendard";
    }
  }

  &-text {
    flex-shrink: 0;
    white-space: nowrap;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.25;

    @for $i from 1 through length($delay-time) {
      /* 리스트 길이 만큼 순회 */
      div:nth-child(#{$i}) {
        span {
          display: inline-block;
          translate: none;
          rotate: none;
          scale: none;
          transform: translate(0px, 0%);
          will-change: transform;

          animation-name: introTextAni;
          animation-duration: 1s;
          animation-delay: nth($delay-time, $i);
          animation-fill-mode: backwards;
        }
      }
    }
  }
  &-main-text {
    font-size: 5rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Pretendard";
  }
  &-sub-text {
    font-size: 2rem;
    font-family: "Pretendard";
    font-weight: 400;
    text-transform: uppercase;

    p {
      font-family: "Pretendard";
      br {
        display: none;
      }
    }
    h1 {
      margin: 0.6875rem 0 1.9375rem;
      span {
        font-size: 3.75rem;
        font-family: "Pretendard";
        font-weight: 600;
        text-transform: lowercase;
      }
    }
  }
  &-ko-text {
    width: 100%;
    margin-top: 2.125rem;
    font-size: 1.5rem;
    font-family: "Pretendard";
    font-weight: 400;
    text-align: center;
    white-space: pre-wrap;

    > div {
      display: inline-block;

      br {
        display: block;
      }
    }
  }

  .btn-group {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0.5rem;
    margin-top: 2.625rem;
    column-gap: 1.25rem;
  }

  @keyframes introTextAni {
    0% {
      transform: translate3d(0px, 120%, 0px);
    }
    100% {
      transform: translate3d(0px, 0%, 0px);
    }
  }
}

.service {
  position: relative;
  padding: 7.5rem 0 8.875rem;
  background-image: linear-gradient(to top, #cad3e6 0%, #eef3ff 100%);

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    text-align: center;
    font-family: "Pretendard";
  }

  .sub-title {
    display: block;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-family: "Pretendard";
  }

  &-box {
    margin-top: 3.75rem;
    margin-bottom: 2.375rem;
    padding: 0 18.75vw;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .btn-group {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 18.5625rem;
      padding: 1.25rem 3.57rem 1.5rem;
      // height: 4.6875rem;
      margin: 0 auto;
      font-size: 1.5rem;
      color: #fff;
      font-weight: 600;
      background-color: #5b54fe;
      border-radius: 999px;
      box-shadow: 0 1.5rem 2rem 0 rgba(24, 45, 112, 0.36);
      font-family: "Pretendard";
    }
  }

  .color-primary {
    color: #5f5aff;
  }
}

.definition {
  padding: 7.5rem 13.5417vw 14.25rem;

  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
  }

  .desc-text {
    display: block;
    margin: 2.5rem auto 6rem;
    font-size: 1.5rem;
    text-align: center;
  }

  &-box {
    position: relative;
    width: 55.5rem;
    height: 55.5rem;
    margin: 0 auto;
    border-radius: 50%;
    background-color: rgba(230, 233, 233, 0.71);
    box-shadow: 1px 0 20px 0 rgba(91, 84, 254, 0.1);

    ul {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 16.375rem;

      li {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 21rem;
        height: 21rem;
        border: solid 1px #e5e6ee;
        background-color: #fff;
        border-radius: 50%;

        &:nth-child(1) {
          top: -10.5rem;
          left: 50%;
          transform: translateX(-50%);
        }
        &:nth-child(2) {
          top: 13.25rem;
          right: -10.5rem;
        }
        &:nth-child(3) {
          bottom: -15.25rem;
          right: -10.5rem;
          transform: translate(-50%, -50%);
        }
        &:nth-child(4) {
          bottom: -15.25rem;
          left: 10.5rem;
          transform: translate(-50%, -50%);
        }
        &:nth-child(5) {
          top: 13.25rem;
          left: -10.5rem;
        }
        .definition-text {
          display: block;
          margin: 0.5rem auto 1.25rem;
          font-size: 1.5rem;
        }
        .detail-text {
          font-size: 1.5rem;
          line-height: 1.3;
          text-align: center;
          font-weight: 600;
          font-family: "Pretendard";
          white-space: pre-wrap;
        }
      }
    }

    .img-box {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 28.25rem;
      max-height: 28.25rem;
      transform: translate(-50%, -50%);

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .color-primary {
    color: #5f5aff;
  }
}

.advantage {
  .title {
    margin-bottom: 2.5rem;
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }

  .advantage-list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    max-width: 87.5rem;
    width: 100%;
    margin: 0 auto;

    p {
      line-height: 1.25;
    }

    span {
      display: inline-block;
      line-height: 1.25;
    }
  }

  .advantage-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // height: 28rem;
    color: #fff;
    overflow: hidden;

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .content-box {
        padding-left: 0;
        padding-right: 6.1458vw;
      }
    }

    &:nth-child(1) {
      .img-box {
        width: 32%;
      }
    }
    &:nth-child(2) {
      .img-box {
        width: 32%;
      }
    }

    .img-box {
      position: relative;
      width: 40%;
      height: 100%;
      border-radius: 1.375rem;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-box {
      width: 60%;
      padding-right: 0;
      padding-left: 6.1458vw;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .content-title {
        display: block;
        margin: 0 0 1.25rem;
        font-size: 2.5rem;
        font-weight: 600;
        color: #5f5aff;

        @media screen and (max-width: 1024px) {
          font-size: 1.5rem;
        }
      }
      .content-text {
        margin-bottom: 3rem;
        font-size: 2.8125rem;
        line-height: 1.67;
      }
      .content-detail {
        display: block;
        margin: 3rem 0;
        font-size: 1.5rem;
        line-height: 1.5;
      }
      .content-helper-text {
        line-height: 1.6875;
      }
    }
  }
}

.process {
  padding: 10rem 13.5417vw 0;
  color: #fff;
  background-color: #1d212e;

  .title {
    margin-bottom: 6rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.187;
    text-align: center;
  }

  .process-list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  .process-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 28rem;
    border-radius: 3rem;
    border: solid 1px rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-image: linear-gradient(
      310deg,
      #1d212e 5%,
      rgba(228, 228, 228, 0.15) 95%
    );
    overflow: hidden;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .img-box {
      position: relative;
      width: 51.21%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-box {
      width: 48.79%;
      padding: 0 4.375rem;
      letter-spacing: -0.02em;
      white-space: pre-wrap;
      word-break: keep-all;

      .num {
        display: block;
        font-size: 3.25rem;
        color: #8d96ab;
      }

      .content-title {
        display: block;
        margin: 0.75rem 0 3rem;
        font-size: 3rem;
        font-weight: 600;
        color: #5f5aff;
      }
      .content-text {
        font-size: 1.5rem;
        line-height: 1.67;
      }
    }
  }
}

// common
.gradient-bg-section {
  background-image: linear-gradient(to top, #ededed, #fff);
}

.bg-area {
  padding: 10rem 0;
  background-size: cover;
}

.btn-show-layer {
  z-index: 999;
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1921px) {
  .intro {
    .inner {
      justify-content: flex-end;
      padding-top: 0;
      padding-bottom: 17.75rem;
    }
  }
  .service {
    padding-top: 124px;
    padding-bottom: 142px;
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
  .definition {
    .title {
      font-size: 60px;
      line-height: 79px;
    }
  }
  .advantage {
    .title {
      font-size: 60px;
      line-height: 79px;
      margin-bottom: 79px;
    }
  }
}
@media screen and (max-width: 1536px) {
  .intro {
    .inner {
      padding-bottom: 10rem;
    }
  }
  .service {
    &-box {
      // padding: 0 4.25rem;
    }
  }

  .definition {
    padding: 7.5rem 4.25rem 14.25rem;
  }

  .advantage {
    padding: 0 4.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    &-sub-text {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    &-main-text {
      font-size: 2.5rem;
    }
    &-ko-text {
      margin-top: 1.5rem;
      font-size: 1rem;

      br {
        display: none;
      }
    }
    .btn-group {
      margin-top: 1.5rem;
    }
    .inner {
      padding-top: 23.125rem;
      button {
        // width: 6.5625rem;
        height: 2.5rem;
        font-size: 0.75rem;
      }
    }
  }
  .service {
    padding: 4rem 0;
    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    .sub-title {
      font-size: 1rem;
    }
    .btn-group {
      button {
        // width: 7.8125rem;
        height: 2.5rem;
        font-size: 0.75rem;
      }
    }
  }
  .definition {
    padding: 4rem 0 6.6875rem;

    .title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    .desc-text {
      margin: 0 2.5rem;
      font-size: 1rem;
    }
    &-box {
      width: 36rem;
      height: 36rem;

      &::before {
        width: 18.375rem;
        height: 18.375rem;
        font-size: 4.5rem;
      }

      ul {
        margin-top: 9.3125rem;
        li {
          width: 13.5rem;
          height: 13.5rem;
          &:nth-child(1) {
            top: -6.75rem;
          }
          &:nth-child(2) {
            top: 10.25rem;
            right: -7.5rem;
          }
          &:nth-child(3) {
            bottom: -12.25rem;
            right: -5.5rem;
          }
          &:nth-child(4) {
            bottom: -12.25rem;
            left: 8.5rem;
          }
          &:nth-child(5) {
            top: 10.25rem;
            left: -7.5rem;
          }
          img {
            width: auto;
            height: 33%;
          }
          .definition-text {
            margin: 1.5rem 0 0.5rem;
            font-size: 1rem;
          }
          .detail-text {
            font-size: 0.9375rem;
          }
        }
      }
    }
  }
  .bg-area {
    padding: 4rem 0;
    .title {
      margin-bottom: 3rem;
      font-size: 2.5rem;
    }
  }
  .advantage {
    .advantage-item {
      border-radius: 0;
      .content-box {
        .content-text {
          font-size: 2rem;
        }
        .content-detail {
          margin: 1.75rem 0 1.5625rem;
          font-size: 1rem;
        }

        .content-helper-text {
          font-size: 0.875rem;
        }
      }
    }
  }
  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}
@media screen and (max-width: 768px) {
  .intro {
    .inner {
      padding: 0 1.5rem 7rem;
      // justify-content: center;

      button {
        // width: 7.75rem;
        height: 2.75rem;
        font-size: 1rem;
      }
    }

    &-text {
      word-break: keep-all;
    }

    &-main-text {
      font-size: 2rem;
      text-align: center;
    }

    &-sub-text {
      margin-top: 0;
      margin-bottom: 0.75rem;
      font-size: 1rem;

      h1 {
        span {
          font-size: inherit;
        }
      }
    }

    &-ko-text {
      margin-top: 1.5rem;
      font-size: 1rem;

      br {
        display: block;
      }
    }

    .btn-group {
      column-gap: 0.5rem;
    }
  }

  .service {
    // padding: 3.25rem 0 0;

    .title {
      font-size: 1.75rem;
      font-weight: 700;
    }

    .sub-title {
      font-size: 0.9375rem;
    }

    &-box {
      padding: 0;
    }
    .btn-group {
      button {
        // width: 10.625rem;
        height: 2.6875rem;
        font-size: 1rem;
      }
    }
  }

  .definition {
    padding: 3.25rem 0 3rem;

    .title {
      margin-bottom: 0;
      font-size: 1.75rem;
    }

    .desc-text {
      font-size: 0.9375rem;
      margin: 0.75rem 0 1.5rem;
    }

    &-box {
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: #fff;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);

      &::before {
        display: none;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 1.5rem;
        margin-top: 0;

        li {
          position: relative;
          width: 16.75rem;
          height: 16.75rem;
          margin: 0 auto;

          &:nth-child(1) {
            top: 0;
            left: 0;
            transform: translateX(0);
          }

          &:nth-child(2) {
            top: 0;
            right: 0;
          }

          &:nth-child(3) {
            bottom: 0;
            right: 0;
            transform: translate(0, 0);
          }

          &:nth-child(4) {
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
          }

          &:nth-child(5) {
            top: 0;
            left: 0;
          }

          .detail-text {
            font-size: 0.9375rem;
            font-weight: 400;
          }

          img {
            display: block;
            width: auto;
            height: 30%;
          }
        }
      }

      .img-box {
        display: none;
      }
    }
  }

  .advantage {
    padding: 0 1.5rem;

    .title {
      font-size: 1.75rem;
    }

    .advantage-list {
      row-gap: 0;
    }

    .advantage-item {
      flex-direction: column;
      height: 100%;
      border-radius: 1rem;

      &:nth-child(odd) {
        flex-direction: column;

        .content-box {
          padding: 0;
        }
      }

      .img-box {
        width: 100%;
      }

      .content-box {
        width: 100%;
        padding: 0;

        .content-title {
          display: inline-block;
          margin: 1.5rem 0 0.75rem 0;
          font-size: 1.125rem;
          white-space: nowrap;
        }

        .content-text {
          font-size: 1.5rem;
          margin-bottom: 0.875rem;
        }

        .content-detail {
          margin: 0.875rem 0 1.5rem;
          font-size: 0.9375rem;
        }

        .content-helper-text {
          display: block;
          margin-bottom: 1.5rem;
          font-size: 0.8125rem;
          color: #8d96ab;
        }
      }
    }
  }

  .process {
    padding: 3rem 1.5rem;

    .title {
      font-size: 1.75rem;
      margin-bottom: 1.75rem;
    }

    .process-item {
      flex-direction: column;
      height: 100%;
      border-radius: 1rem;

      &:nth-child(even) {
        flex-direction: column;
      }

      .img-box {
        width: 100%;
        height: 10rem;
      }

      .content-box {
        width: 100%;
        padding: 1.5rem 1rem;

        .num {
          display: inline-block;
          font-size: 0.9375rem;
        }
        .content-title {
          display: inline-block;
          margin: 0 0 0.75rem 0.5rem;
          font-size: 0.9375rem;
        }
        .content-text {
          font-size: 0.9375rem;
        }
      }
    }
  }
}
