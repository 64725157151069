.link-popup-wrap {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  transform: translate(-50%, -50%);
  padding-top: 3rem;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 27px 0px rgba(65, 65, 64, 0.05);
  border-radius: 0.5rem;
  overflow: hidden;

  & > div {
    height: 100%;

    iframe {
      height: 100%;
    }
  }
}
.btn-close-popup {
  z-index: 99;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  filter: invert(70%);
  cursor: "pointer";

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
