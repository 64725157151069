.media {
  padding: 13.125rem 0 10rem;
  background-image: url("../../assets/images/bg/media.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    margin-bottom: 6rem;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.32;
    text-align: center;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 5rem;
    border: solid 2px rgba(255, 255, 255, 0.48);
    border-radius: 3rem;
    background-image: url("../../assets/images/bg/media_blur.png");

    li {
      width: calc((100% - 3rem) / 3);
      border-radius: 1.5rem;
      overflow: hidden;
    }

    .img-box {
      img {
        display: block;
        width: 100%;
        // height: 100%;
        height: 167px;
        object-fit: cover;
      }
    }

    .media-box {
      display: flex;
      flex-direction: column;
      height: 17.4rem;
      padding: 1.5rem 1.5rem 2rem;
      background-color: #fff;

      .media-title {
        flex-shrink: 0;
        display: block;
        height: 4.5rem;
        margin-bottom: 1rem;
        font-size: 1.75rem;
        line-height: 1.29;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .content-box {
        font-size: 1rem;
        line-height: 1.5;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .bottom-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        padding-top: 1rem;

        .date {
          font-size: 1rem;
          color: #8d96ab;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.75rem 2rem;
          font-size: 1rem;
          color: #fff;
          background-color: #5b54fe;
          border-radius: 999px;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  max-width: max-content;
  width: 100%;
  margin: 3rem auto 0;
  padding: 2.0625rem 3.1875rem;
  column-gap: 2.0625rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border: solid 2px rgba(255, 255, 255, 0.55);
  border-radius: 999px;
  background-image: linear-gradient(97deg, #adadad 1%, #9c9d9f 98%);

  &-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.875rem;

    button {
      font-size: 1.25rem;
      color: #fff;

      &:hover {
        font-weight: 700;
      }
    }
  }
}

.bg-wrapper {
  padding-bottom: 10rem;
  background-color: #1d212e;
}

// common
.btn-show-layer {
  position: fixed;
  right: 2rem;
  bottom: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.3125rem;
  height: 3.375rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  background-color: #5b54fe;
  border-radius: 999px;
  cursor: pointer;
  text-transform: capitalize;
  animation: admin-sticker 1s linear alternate infinite forwards;
}

@keyframes admin-sticker {
  0% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.title {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  &.active {
    animation: titleAni 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  }
}

@keyframes titleAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1280px) {
  .media {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .media-wrapper {
    padding-top: 0;
  }
  .media {
    padding-bottom: 4rem;

    .title {
      font-size: 2.5rem;
      margin-bottom: 4.625rem;
    }
    &-list {
      padding: 3.25rem;
      gap: 1rem;

      li {
        width: calc((100% - 2rem) / 3);
      }
    }

    .media-box {
      height: 13.5625rem;
      padding: 1rem;

      .media-title {
        height: max-content;
        max-height: 3.5rem;
        padding-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.37;
      }

      p {
        max-height: max-content;
        height: 5.875rem;
        line-height: 1.4;
        font-size: 1rem;
        -webkit-line-clamp: 4;
      }

      .bottom-box {
        button {
          padding: 0.375rem 1.0625rem;
          font-size: 0.8125rem;
        }
        .date {
          font-size: 0.8125rem;
        }
      }
    }
  }
  .pagination {
    margin-top: 2rem;
    padding: 1.5rem 3.625rem;
  }
  .bg-wrapper {
    padding-bottom: 4rem;
  }
  .btn-show-layer {
    width: 7.3125rem;
    font-size: 1rem;
    height: 2.375rem;
  }
}

@media screen and (max-width: 768px) {
  .media {
    padding-top: 6.75rem;
    padding-right: 0;
    padding-bottom: 3rem;

    .title {
      width: 100%;
      margin-bottom: 1.5rem;
      font-size: 1.75rem;
      padding-right: 1.5rem;
      word-break: keep-all;
    }

    &-list {
      display: flex;
      flex-wrap: nowrap;
      max-width: max-content;
      width: max-content;
      padding: 1.25rem;

      li {
        flex-shrink: 0;
        width: 14.4375rem;
      }
    }
  }

  .scroll-x {
    overflow-x: auto;
    padding-bottom: 0.625rem;
    padding-right: 1.25rem;

    &::-webkit-scrollbar {
      border-radius: 999px;
      height: 2px;
      background-color: #707070;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5b54fe;
      border-radius: 999px;
    }
    &::-webkit-scrollbar-track {
    }
  }

  .pagination {
    display: none;
  }
}
